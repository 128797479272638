<template>
  <section class="menu">
    <div class="container my-container">
      <nav class="navbar navbar-expand-xl navbar-light">
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav">
            <li
              :class="[
                item.children.length == 0 ? 'nav-item' : 'nav-item dropdown',
              ]"
              v-for="item in menu"
              :key="item.id"
            >
              <a
                v-if="item.children.length > 0"
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span v-if="$i18n.locale == 'uz' && item.translations && item.translations.uz && item.translations.uz.title">{{ item.translations.uz.title }}</span>
                <span v-if="$i18n.locale == 'ru' && item.translations && item.translations.ru && item.translations.ru.title">{{ item.translations.ru.title }}</span>
                <span v-if="$i18n.locale == 'oz' && item.translations && item.translations.oz && item.translations.oz.title">{{ item.translations.oz.title }}</span>
                <span v-if="$i18n.locale == 'en' && item.translations && item.translations.en && item.translations.en.title">{{ item.translations.en.title }}</span>
                <i class="fas fa-chevron-down"></i>
              </a>

              <router-link
                class="nav-link"
                v-if="item.children.length === 0 && !item.news_id"
                :to="{ name: item.name }"
                >
                  
                  <span v-if="$i18n.locale == 'uz' && item.translations && item.translations.uz && item.translations.uz.title">{{ item.translations.uz.title }}</span>
                  <span v-if="$i18n.locale == 'ru' && item.translations && item.translations.ru && item.translations.ru.title">{{ item.translations.ru.title }}</span>
                  <span v-if="$i18n.locale == 'oz' && item.translations && item.translations.oz && item.translations.oz.title">{{ item.translations.oz.title }}</span>
                  <span v-if="$i18n.locale == 'en' && item.translations && item.translations.en && item.translations.en.title">{{ item.translations.en.title }}</span>
                </router-link
              >

              <router-link
                class="nav-link"
                v-if="item.children.length === 0 && item.news_id"
                :to="{ name: 'page', params: { id : item.news_id } }"
                >
                  
                  <span v-if="$i18n.locale == 'uz' && item.translations && item.translations.uz && item.translations.uz.title">{{ item.translations.uz.title }}</span>
                  <span v-if="$i18n.locale == 'ru' && item.translations && item.translations.ru && item.translations.ru.title">{{ item.translations.ru.title }}</span>
                  <span v-if="$i18n.locale == 'oz' && item.translations && item.translations.oz && item.translations.oz.title">{{ item.translations.oz.title }}</span>
                  <span v-if="$i18n.locale == 'en' && item.translations && item.translations.en && item.translations.en.title">{{ item.translations.en.title }}</span>
                </router-link
              >
              
              <treemenu
                v-if="item.children.length > 0"
                :datatree="item.children"
              ></treemenu>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </section>
  <section :class=" $store.state.bars ? 'full-section active' : 'full-section'">
    <div class="container my-container">
      <treefull :datatree="menu"></treefull>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import treefull from "./treefull.vue";
import treemenu from "./treemenu.vue";
export default {
  components: { treemenu, treefull },
  name: "mymenu",
  data() {
    return {
      url: process.env.VUE_APP_API_BASE_URL + `cms/menu/`,
      menu: [],
    };
  },
  mounted() {
    this.getmenu();
  },
  methods: {
    getmenu() {
      axios
        .get(this.url)
        .then((response) => {
          this.menu = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
