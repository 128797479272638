<template>
  <footer>
    <div class="container">
      <div class="row">
        <div class="col-xl-4 col-md-6">
          <div class="flogo">
            <router-link :to="{ name: 'home' }">
              <div class="flogo-in">
                <div class="flogo-icon">
                  <img src="/img/flogo.png" />
                </div>
                <div class="flogo-text" v-html="$t('flogo')"></div>
              </div>
            </router-link>
          </div>
        </div>
        <div class="col-xl-3 col-md-3">
          <ul class="navbar-nav f-ul">
            <li
              :class="[
                item.children.length == 0 ? 'nav-item' : 'nav-item dropdown',
              ]"
              v-for="item in menu"
              :key="item.id"
            >
              <a
                v-if="item.children.length > 0"
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span v-if="$i18n.locale == 'uz' && item.translations && item.translations.uz && item.translations.uz.title">{{ item.translations.uz.title }}</span>
                <span v-if="$i18n.locale == 'ru' && item.translations && item.translations.ru && item.translations.ru.title">{{ item.translations.ru.title }}</span>
                <span v-if="$i18n.locale == 'oz' && item.translations && item.translations.oz && item.translations.oz.title">{{ item.translations.oz.title }}</span>
                <span v-if="$i18n.locale == 'en' && item.translations && item.translations.en && item.translations.en.title">{{ item.translations.en.title }}</span>
              </a>

              <router-link
                class="nav-link"
                v-if="item.children.length === 0 && !item.news_id"
                :to="{ name: item.name }"
                >
                  
                  <span v-if="$i18n.locale == 'uz' && item.translations && item.translations.uz && item.translations.uz.title">{{ item.translations.uz.title }}</span>
                  <span v-if="$i18n.locale == 'ru' && item.translations && item.translations.ru && item.translations.ru.title">{{ item.translations.ru.title }}</span>
                  <span v-if="$i18n.locale == 'oz' && item.translations && item.translations.oz && item.translations.oz.title">{{ item.translations.oz.title }}</span>
                  <span v-if="$i18n.locale == 'en' && item.translations && item.translations.en && item.translations.en.title">{{ item.translations.en.title }}</span>
                </router-link
              >

              <router-link
                class="nav-link"
                v-if="item.children.length === 0 && item.news_id"
                :to="{ name: 'page', params: { id : item.news_id } }"
                >
                  
                  <span v-if="$i18n.locale == 'uz' && item.translations && item.translations.uz && item.translations.uz.title">{{ item.translations.uz.title }}</span>
                  <span v-if="$i18n.locale == 'ru' && item.translations && item.translations.ru && item.translations.ru.title">{{ item.translations.ru.title }}</span>
                  <span v-if="$i18n.locale == 'oz' && item.translations && item.translations.oz && item.translations.oz.title">{{ item.translations.oz.title }}</span>
                  <span v-if="$i18n.locale == 'en' && item.translations && item.translations.en && item.translations.en.title">{{ item.translations.en.title }}</span>
                </router-link
              >
              

              <treemenu
                v-if="item.children.length > 0"
                :datatree="item.children"
              ></treemenu>
            </li>
          </ul>
        </div>
        <div class="col-xl-2 col-md-3">
          <ul class="f-ul-two">
            <li>
              <div class="fone">
                <span>{{ $t("phone") }}</span>
              </div>
              <div class="ftwo" v-if="phone.length > 0">
                <a :href="'tel:' + phone[0].callable_number">{{
                  phone[0].number
                }}</a>
              </div>
            </li>
            <li>
              <div class="fone">
                <span>{{ $t("work") }}</span>
              </div>
              <div class="ftwo">
                <span>{{ $t("time") }}</span>
              </div>
            </li>
            <li>
              <div class="fone">
                <span>{{ $t("lunch") }}</span>
              </div>
              <div class="ftwo">
                <span>13:00 - 14:00</span>
              </div>
            </li>
          </ul>
        </div>
        <div class="col-xl-3 col-md-6">
          <ul class="f-ul-two">
            <li>
              <div class="fone">
                <span>{{ $t("days") }}</span>
              </div>
              <div class="ftwo">
                <span v-html="$t('mydays')"></span>
              </div>
            </li>
            <li>
              <div class="fone">
                <span>{{ $t("call") }}</span>
              </div>
              <div class="ftwo" v-if="call.length > 0">
                <span
                  >- {{ call[0].number }} <br />
                  {{ $t("calltext") }}</span
                >
              </div>
              <div class="ftwo" v-if="call.length > 1">
                <span
                  >- {{ call[1].number }} <br />
                  {{ $t("calltext2") }}</span
                >
              </div>
            </li>
          </ul>
        </div>
        <div class="col-xl-4 col-md-6">
          <p class="f-link" v-html="$t('flink')"></p>
          <p class="copy">© {{ $t("copy") }}</p>
        </div>
        <div class="col-xl-4 col-md-6">
          <p class="fctrl">
            {{ $t("ctrl") }}
          </p>
        </div>
        <div class="col-xl-4 col-md-6">
          <div class="uz">
            <a href="https://uzinfocom.uz/uz/" target="_blank">
              <img src="/img/uz.png" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
  <div class="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">{{ $t('search') }}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="my-form">
            <form class="form-inline">
              <input class="form-control mr-sm-2" v-model="search" type="search" :placeholder="$t('search')" aria-label="Search">
              <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
            </form>
          </div>
          
          <div class="result-content">
            <div class="result mt-3" v-for="detail in filteredlist" :key="detail.id">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title">
                    <span v-if="$i18n.locale == 'uz' && detail.translations && detail.translations.uz && detail.translations.uz.headline">{{ detail.translations.uz.headline }}</span>
                    <span v-if="$i18n.locale == 'ru' && detail.translations && detail.translations.ru && detail.translations.ru.headline">{{ detail.translations.ru.headline }}</span>
                    <span v-if="$i18n.locale == 'oz' && detail.translations && detail.translations.oz && detail.translations.oz.headline">{{ detail.translations.oz.headline }}</span>
                    <span v-if="$i18n.locale == 'en' && detail.translations && detail.translations.en && detail.translations.en.headline">{{ detail.translations.en.headline }}</span>
                  </h5>
                  <router-link class="btn btn-success" :to="{ name: 'newsdetail', params: { id: detail.id }}" data-dismiss="modal" aria-label="Close">
                    {{ $t('read') }}
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import treemenu from './treemenu.vue';
export default {
  components: { treemenu },
  name: "myfooter",
  data() {
    return {
      urlphone: process.env.VUE_APP_API_BASE_URL + `cms/phone_numbers/`,
      urlnews: process.env.VUE_APP_API_BASE_URL + `n/news/`,
      url: process.env.VUE_APP_API_BASE_URL + `cms/menu/`,
      menu: [],
      number: [],
      snews: [],
      search: '',
    };
  },
  mounted() {
    this.getdata();
  },
  methods: {
    getdata() {
      axios
        .get(this.urlphone)
        .then((response) => {
          this.number = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      axios
        .get(this.urlnews)
        .then((response) => {
          this.snews = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      axios
        .get(this.url)
        .then((response) => {
          this.menu = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {
    phone: function () {
      return this.number.filter((item) => {
        if (item.number_type == "phone_number") {
          return item;
        }
      });
    },
    call: function () {
      return this.number.filter((item) => {
        if (item.number_type == "call_center") {
          return item;
        }
      });
    },
    filteredlist() {
      return this.snews.filter((post) => {
        if (this.$i18n.locale == 'uz' && post.translations && post.translations.uz && post.translations.uz.headline ) {
          return post.translations.uz.headline.toLowerCase().includes(this.search.toLowerCase());  
        }
        if (this.$i18n.locale == 'ru' && post.translations && post.translations.ru && post.translations.ru.headline ) {
          return post.translations.ru.headline.toLowerCase().includes(this.search.toLowerCase());  
        }
        if (this.$i18n.locale == 'oz' && post.translations && post.translations.oz && post.translations.oz.headline ) {
          return post.translations.oz.headline.toLowerCase().includes(this.search.toLowerCase());  
        }
        if (this.$i18n.locale == 'en' && post.translations && post.translations.en && post.translations.en.headline ) {
          return post.translations.en.headline.toLowerCase().includes(this.search.toLowerCase());  
        }
      })
    },
  },
};
</script>