<template>
  <myheader></myheader>
  <mymenu></mymenu>
  <banner :title="$t('video')"></banner>
  <section class="page">
    <div class="container">
      <div class="row">
        <div class="col-md-4" v-for="item in video" :key="item.id">
          <div class="video">

            <a
              v-if="item.youtube_url"
              data-fancybox
              :href="item.youtube_url"
              :data-caption="item.name"
            >
              <div class="video-in play">
                <img :src="item.cover_image" />
              </div>
              <div class="v-title">
                <span v-if="$i18n.locale == 'uz' && item.translations && item.translations.uz && item.translations.uz.headline">{{ item.translations.uz.headline }}</span>
                <span v-if="$i18n.locale == 'ru' && item.translations && item.translations.ru && item.translations.ru.headline">{{ item.translations.ru.headline }}</span>
                <span v-if="$i18n.locale == 'oz' && item.translations && item.translations.oz && item.translations.oz.headline">{{ item.translations.oz.headline }}</span>
                <span v-if="$i18n.locale == 'en' && item.translations && item.translations.en && item.translations.en.headline">{{ item.translations.en.headline }}</span>
              </div>
            </a>

            <a
              v-if="item.utube_url"
              data-fancybox
              data-type="iframe"
              :data-src="item.utube_url"
              href="javascript:;"
            >
              <div class="video-in play">
                <img :src="item.cover_image" alt="video" />
              </div>
              <div class="v-title">
                <span v-if="$i18n.locale == 'uz' && item.translations && item.translations.uz && item.translations.uz.headline">{{ item.translations.uz.headline }}</span>
                <span v-if="$i18n.locale == 'ru' && item.translations && item.translations.ru && item.translations.ru.headline">{{ item.translations.ru.headline }}</span>
                <span v-if="$i18n.locale == 'oz' && item.translations && item.translations.oz && item.translations.oz.headline">{{ item.translations.oz.headline }}</span>
                <span v-if="$i18n.locale == 'en' && item.translations && item.translations.en && item.translations.en.headline">{{ item.translations.en.headline }}</span>
              </div>
            </a>

            <a
              v-if="item.video_file"
              data-fancybox
              :href="item.video_file"
              :data-caption="item.name"
            >
              <div class="video-in play">
                <img :src="item.cover_image" />
              </div>
              <div class="v-title">
                <span v-if="$i18n.locale == 'uz' && item.translations && item.translations.uz && item.translations.uz.headline">{{ item.translations.uz.headline }}</span>
                <span v-if="$i18n.locale == 'ru' && item.translations && item.translations.ru && item.translations.ru.headline">{{ item.translations.ru.headline }}</span>
                <span v-if="$i18n.locale == 'oz' && item.translations && item.translations.oz && item.translations.oz.headline">{{ item.translations.oz.headline }}</span>
                <span v-if="$i18n.locale == 'en' && item.translations && item.translations.en && item.translations.en.headline">{{ item.translations.en.headline }}</span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <myfooter></myfooter>
</template>

<script>
import axios from "axios";
import banner from "../components/banner.vue";
import myfooter from "../components/myfooter.vue";
import myheader from "../components/myheader.vue";
import mymenu from "../components/mymenu.vue";
export default {
  components: { myheader, mymenu, banner, myfooter },
  name: "videolist",
  data() {
    return {
      url: process.env.VUE_APP_API_BASE_URL + `cms/videos/`,
      video: [],
    };
  },
  mounted() {
    this.getdata();
  },
  methods: {
    getdata() {
      axios
        .get(this.url)
        .then((response) => {
          this.video = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.video-in {
  position: relative;
}
.video-in:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(51, 51, 51, 0.4);
}
.video-in img {
  width: 100%;
  height: 210px;
  object-fit: cover;
  object-position: center;
}
.v-title {
  margin-top: 10px;
}
.v-title span {
  font-weight: 600;
  color: rgba(51, 51, 51, 1);
  font-size: 18px;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
@media (min-width: 576px) {
  .video {
    margin-top: 30px;
  }
}
@media (max-width: 575px) {
  .video {
    margin-top: 15px;
  }
}
</style>