<template>
  <myheader></myheader>
  <mymenu></mymenu>
  <banner :title="$t('foto')"></banner>
  <section class="page">
    <div class="container">
      <div class="my-foto" v-for="item in images" :key="item.id">
        <div class="foto-title">
          <span v-if="$i18n.locale == 'uz' && item.translations && item.translations.uz && item.translations.uz.title">{{ item.translations.uz.title }}</span>
          <span v-if="$i18n.locale == 'ru' && item.translations && item.translations.ru && item.translations.ru.title">{{ item.translations.ru.title }}</span>
          <span v-if="$i18n.locale == 'oz' && item.translations && item.translations.oz && item.translations.oz.title">{{ item.translations.oz.title }}</span>
          <span v-if="$i18n.locale == 'en' && item.translations && item.translations.en && item.translations.en.title">{{ item.translations.en.title }}</span>
        </div>
        <div class="row">
          <div
            class="col-xl-4 col-md-6"
            v-for="item in item.event_image"
            :key="item.id"
          >
            <div class="foto">
              <a data-fancybox="single" :href="item.image_file">
                <div class="foto-in">
                  <img :src="item.image_file" />
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <myfooter></myfooter>
</template>

<script>
import banner from "../components/banner.vue";
import myfooter from "../components/myfooter.vue";
import myheader from "../components/myheader.vue";
import mymenu from "../components/mymenu.vue";
import axios from "axios";
export default {
  components: { myheader, mymenu, banner, myfooter },
  name: "fotolist",
  data() {
    return {
      url: process.env.VUE_APP_API_BASE_URL + `cms/events/`,
      images: [],
    };
  },
  mounted() {
    this.getfotos();
  },
  methods: {
    getfotos() {
      axios
        .get(this.url)
        .then((response) => {
          this.images = response.data;
          console.log(this.images);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.foto-title {
  margin-top: 30px;
}
.foto-title span {
  font-weight: 700;
  font-size: 18px;
}
.foto-in img {
  width: 100%;
  height: 210px;
  object-fit: cover;
}
@media (min-width: 576px) {
  .foto {
    margin-top: 30px;
  }
}
@media (max-width: 575px) {
  .foto {
    margin-top: 15px;
  }
}
</style>