<template>
  <myheader></myheader>
  <mymenu></mymenu>
  <banner :title="$t('newstitle')"></banner>
  <section class="page">
    <div class="container">
      <div class="row">
        <div class="col-xl-4 col-md-6" v-for="item in news" :key="item.id">
          <div class="news-right">
            <div class="news-right-img">
              <router-link
                :to="{ name: 'newsdetail', params: { id: item.id } }"
              >
                <img :src="item.image" />
              </router-link>
            </div>
            <div class="news-right-title">
              <router-link
                :to="{ name: 'newsdetail', params: { id: item.id } }"
              >
                <span v-if="$i18n.locale == 'uz' && item.translations && item.translations.uz && item.translations.uz.headline">{{ item.translations.uz.headline }}</span>
                <span v-if="$i18n.locale == 'ru' && item.translations && item.translations.ru && item.translations.ru.headline">{{ item.translations.ru.headline }}</span>
                <span v-if="$i18n.locale == 'oz' && item.translations && item.translations.oz && item.translations.oz.headline">{{ item.translations.oz.headline }}</span>
                <span v-if="$i18n.locale == 'en' && item.translations && item.translations.en && item.translations.en.headline">{{ item.translations.en.headline }}</span>
              </router-link>
            </div>
            <div class="news-right-date">
              <div class="news-right-time">
                <img src="/img/date.png" />
                <span>{{ item.created_at }}</span>
                <span> | </span>
                <span>{{ item.time }}</span>
              </div>
              <div class="news-right-eye">
                <img src="/img/eyen.png" />
                <span>{{ item.views_count }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <myfooter></myfooter>
</template>

<script>
import banner from "../components/banner.vue";
import myheader from "../components/myheader.vue";
import mymenu from "../components/mymenu.vue";
import axios from "axios";
import myfooter from "../components/myfooter.vue";

export default {
  components: { myheader, mymenu, banner, myfooter },
  name: "newslist",
  data() {
    return {
      url: process.env.VUE_APP_API_BASE_URL + `n/news/`,
      news: [],
    };
  },
  methods: {},
  mounted() {
    axios
      .get(this.url)
      .then((response) => {
        this.news = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>
