<template>
  <section class="social-left">
    <ul class="social-ul">
      <li v-for="item in social" :key="item.id">
        <a :href="item.url">
          <img :src="item.icon" alt="icon" />
        </a>
      </li>
    </ul>
  </section>
  <header>
    <div class="my-container container">
      <div class="row">
        <div class="col-xl-6">
          <div class="header-left">
            <div class="bars">
              <button @click="bars()">
                <img src="/img/bars.png" />
              </button>
            </div>
            <div class="logo">
              <router-link :to="{ name: 'home' }">
                <div class="logo-in">
                  <div class="logo-icon">
                    <img src="/img/logo.png" />
                  </div>
                  <div class="logo-text" v-html="$t('logo')"></div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-xl-6">
          <div class="header-right">
            <ul class="header-ul">
              <li v-if="email.length > 0">
                <a :href="mailto" class="email">
                  <img src="/img/email.png" />
                  <span>{{ email[0].email }}</span>
                </a>
              </li>
              <li>
                <div class="mobile">
                  <button>
                    <img src="/img/mobile.png" />
                  </button>
                </div>
              </li>
              <li>
                <div class="speciel_relative">
                  <div class="special_box">
                    <div
                      class="icon_accessibility dataTooltip"
                      role="button"
                      data-toggle="dropdown"
                      data-placement="bottom"
                      title="Махсус имкониятлар"
                      aria-expanded="true"
                    >
                      <div class="eye">
                        <img class="eye_icon" src="/img/eye.png" alt="eye" />
                      </div>
                    </div>
                    <div
                      class="
                        dropdown-menu dropdown-menu-right
                        specialViewArea
                        no-propagation
                      "
                    >
                      <div class="triangle2"></div>

                      <div class="appearance">
                        <p class="specialTitle">Кўриниш</p>

                        <div class="squareAppearances">
                          <div
                            class="squareBox spcNormal"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title=""
                            data-original-title="Оддий кўриниш"
                          >
                            A
                          </div>
                        </div>
                        <div class="squareAppearances">
                          <div
                            class="squareBox spcWhiteAndBlack"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title=""
                            data-original-title="Оқ-қора кўриниш"
                          >
                            A
                          </div>
                        </div>
                        <div class="squareAppearances">
                          <div
                            class="squareBox spcDark"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title=""
                            data-original-title="Қоронғилашган кўриниш"
                          >
                            A
                          </div>
                        </div>
                      </div>

                      <div class="appearance">
                        <p class="specialTitle d-none">Шрифт ўлчами</p>

                        <div class="block blocked d-none">
                          <div class="sliderText">
                            <span class="range">0</span>% га катталаштириш
                          </div>
                          <div
                            id="fontSizer"
                            class="
                              defaultSlider
                              ui-slider
                              ui-slider-horizontal
                              ui-widget
                              ui-widget-content
                              ui-corner-all
                            "
                          >
                            <div
                              class="
                                ui-slider-range
                                ui-widget-header
                                ui-corner-all
                                ui-slider-range-min
                              "
                              style="width: 0%"
                            ></div>
                            <span
                              class="
                                ui-slider-handle ui-state-default ui-corner-all
                              "
                              tabindex="0"
                              style="left: 0%"
                            ></span>
                          </div>
                        </div>

                        <div class="block">
                          <div class="sliderZoom">
                            <span class="range">0</span> % баробарга
                            катталаштириш
                          </div>
                          <div
                            id="zoomSizer"
                            class="
                              defaultSlider
                              ui-slider
                              ui-slider-horizontal
                              ui-widget
                              ui-widget-content
                              ui-corner-all
                            "
                          >
                            <div
                              class="
                                ui-slider-range
                                ui-widget-header
                                ui-corner-all
                                ui-slider-range-min
                              "
                              style="width: 0%"
                            ></div>
                            <span
                              class="
                                ui-slider-handle ui-state-default ui-corner-all
                              "
                              tabindex="0"
                              style="left: 0%"
                            ></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div class="lang">
                  <a
                    href="#"
                    @click.prevent="setlang('ru')"
                    v-bind:class="$i18n.locale == 'ru' ? 'active' : ''"
                    >RU</a
                  >
                  /
                  <a
                    href="#"
                    @click.prevent="setlang('uz')"
                    v-bind:class="$i18n.locale == 'uz' ? 'active' : ''"
                    >ЎЗ</a
                  >
                  /
                  <a
                    href="#"
                    @click.prevent="setlang('oz')"
                    v-bind:class="$i18n.locale == 'oz' ? 'active' : ''"
                    >O'Z</a
                  >
                  /
                  <a
                    href="#"
                    @click.prevent="setlang('en')"
                    v-bind:class="$i18n.locale == 'en' ? 'active' : ''"
                    >EN</a
                  >
                </div>
              </li>
              <li>
                <div class="search">
                  <button
                    type="button"
                    data-toggle="modal"
                    data-target="#staticBackdrop"
                  >
                    <img src="/img/search.png" alt="image" />
                  </button>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </header>
  <div class="loader-content">
    <ul class="loader">
      <li></li>
      <li></li>
      <li></li>
    </ul>
  </div>
</template>

<script>
import axios from "axios";
import i18n from "../i18n";
import router from "../router";
export default {
  name: "myheader",
  data() {
    return {
      lang: ["uz", "oz", "ru", "en"],
      full: false,
      url: process.env.VUE_APP_API_BASE_URL + `cms/owner_data/`,
      urlsocial: process.env.VUE_APP_API_BASE_URL + `cms/social_media_icons/`,
      email: {},
      social: [],
    };
  },
  mounted() {
    this.getdata();
  },
  methods: {
    setlang(lang) {
      i18n.global.locale = lang;
      router.push({
        params: {
          lang: lang,
        },
      });
    },
    getdata() {
      axios
        .get(this.url)
        .then((response) => {
          this.email = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      axios
        .get(this.urlsocial)
        .then((response) => {
          this.social = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    bars() {
      this.$store.state.bars = !this.$store.state.bars;
    },
  },
  computed: {
    mailto: function () {
      return `mailto:` + this.email[0].email;
    },
  },
};
</script>
<style scoped>
.social-left {
  position: fixed;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 20;
}
.social-ul {
  text-align: center;
  background-color: white;
  border-radius: 5px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.social-ul li {
  margin-top: 20px;
  margin-bottom: 20px;
}
.social-ul li a img {
  transition: 0.3s;
}
.social-ul li a:hover img {
  transform: scale(1.18);
}
@media (max-width: 991px) {
  .social-left {
    display: none;
  }
}
div.loader-content {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 20;
}
ul.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
ul.loader li {
  position: absolute;
  top: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  list-style: none;
  display: inline-block;
  animation: animate 2s infinite linear;
}
ul.loader li:nth-child(1) {
  background: rgba(43, 136, 216, 1);
  left: 0;
  animation-delay: 0s;
}
ul.loader li:nth-child(2) {
  background: #d35400;
  left: -60px;
  animation-delay: 0.4s;
}
ul.loader li:nth-child(3) {
  background: #27ae60;
  left: -120px;
  animation-delay: 0.8s;
}
@keyframes animate {
  0% {
    transform: translateX(-300px);
    opacity: 0;
  }
  35% {
    transform: translateX(0px);
    opacity: 1;
  }
  65% {
    transform: translateX(0px);
    opacity: 1;
  }
  100% {
    transform: translateX(300px);
    opacity: 0;
  }
}

</style>