<template>
  <myheader></myheader>
  <mymenu></mymenu>
  <banner :title="$t('contact')"></banner>
  <section class="page">
    <div class="container">
      <div class="contact-content">
        <div class="row">
          <div class="col-lg-4">
            <div class="contact-location">
              <img src="/img/contact-location.svg" />
              <span>{{ $t('contact_location') }}</span>
            </div>
            <div class="contact-location">
              <img src="/img/contact-phone.svg" />
              <div class="span-block">
                <a href="tel:998781221232">(998 78) 122-12-32</a>
              </div>
            </div>
            <div class="contact-location">
              <img src="/img/contact-email.svg" />
              <a href="mailto:info@iesf.uz">info@iesf.uz</a>
            </div>
            <div class="contact-location">
              <img src="/img/contact-clock.svg" />
              <div class="span-block" v-html="$t('mydays')"></div>
            </div>
            <div class="contact-location buss">
              <div class="contact-bus">
                <i class="fas fa-bus"></i>
              </div>
              <span>14, 53, 58, 78, 93, 98</span>
            </div>
            <div class="contact-location buss">
              <div class="contact-bus">
                <i class="fas fa-subway"></i>
              </div>
              <span>{{ $t('subway') }}</span>
            </div>
            <div class="contact-location buss">
              <div class="contact-bus">
                <i class="fas fa-car-side"></i>
              </div>
              <span>{{ $t('cars') }}</span>
            </div>
            
          </div>
          <div class="col-lg-8">
            <div id="map" class="map" ref="map"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <myfooter></myfooter>
</template>

<script>
import Banner from "../components/banner.vue";
import Myfooter from "../components/myfooter.vue";
import Myheader from "../components/myheader.vue";
import Mymenu from "../components/mymenu.vue";
export default {
  name: "contact",
  components: { Myheader, Mymenu, Banner, Myfooter },
  data() {
    return {
      map: null,
    };
  },
  methods: {
    getelement() {
      let map_element = this.$refs["map"];
      let script = document.createElement("script");
      script.src = `https://api-maps.yandex.ru/services/constructor/1.0/js/?um=constructor%3A1368f2bed6649a482a199aafa630fab2d381872cc8c0b7e9a81b5760619cb874&amp;width=100%25&amp;height=500&amp;lang=ru_RU&amp;scroll=false`;
      map_element.append(script);
    },
  },
  mounted() {
    this.getelement();
  },
  computed: {},
  created() {},
};
</script>
<style scoped>
.buss {
  display: flex;
  align-items: center;
}
.contact-bus {
  width: 40px;
  font-size: 20px;
  padding-left: 2px;
  color: rgb(0 79 151);
}
.buss span {
  width: calc(100% - 40px);
}
.contact-title span {
  font-weight: 500;
  font-size: 24px;
  color: rgba(0, 79, 151, 1);
  letter-spacing: 0.36px;
  text-transform: uppercase;
}
.contact-location {
  display: flex;
  margin-top: 30px;
}
.contact-location img {
  margin-right: 20px;
}
.contact-location a {
  display: block;
}
.map {
  margin-top: 30px;
}
@media (min-width: 576px) {
  .contact-content {
    background-color: rgba(255, 255, 255, 1);
    border-radius: 3px;
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.16);
    padding: 30px;
    margin-bottom: 50px;
  }
}
@media (max-width: 991px) {
  .contact-map {
    margin-top: 30px;
  }
}
@media (max-width: 575px) {
  .contact-content {
    margin-bottom: 30px;
  }
}
</style>
