
<template>
  <section class="p-banner">
    <div class="container">
      <div class="p-title">
        <span>{{ title }}</span>
      </div>
    </div>
  </section>
  <!-- <section class="bread-section">
    <div class="container">
      <div class="bread">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="#">Home</a></li>
            <li class="breadcrumb-item"><a href="#">Library</a></li>
            <li class="breadcrumb-item active" aria-current="page">Data</li>
          </ol>
        </nav>
      </div>
    </div>
  </section> -->
</template>

<script>
export default {
  name: "banner",
  props: ["title"],
};
</script>

<style scoped>
.p-banner {
  padding-top: 50px;
  padding-bottom: 50px;
  background: url("~@/assets/banner.png");
  margin-top: 30px;
  background-position: center;
}
.p-title {
  text-align: center;
  position: relative;
  min-height: 36px;
}
.p-title span {
  font-weight: 600;
  font-size: 24px;
}
.bread {
  margin-top: 30px;
}
.bread nav {}
.bread nav ol {}
.bread nav ol li {}
.bread nav ol li a {
  color: rgba(51, 51, 51, 1);
}
.bread nav ol li a:hover {
  color: rgba(43, 136, 216, 1);
}
</style>