import { createRouter, createWebHistory, RouterView } from 'vue-router'
import home from '../views/Home.vue'
import about from '../views/About.vue'
import i18n from '../i18n'
import error from '../views/error.vue'
import newslist from '../views/newslist.vue'
import newsdetail from '../views/newsdetail.vue'
import fotolist from '../views/fotolist.vue'
import videolist from '../views/videolist.vue'
import annolist from '../views/annolist.vue'
import leader from '../views/leader.vue'
import page from '../views/page.vue'
import application from '../views/application.vue'
import tenderlist from '../views/tenderlist.vue'
import contact from '../views/contact.vue'

const routes = [
  {
    path: '/',
    redirect: `${i18n.global.locale}`
  },
  {
    path: '/:lang',
    component: <RouterView></RouterView>,
    children: [
      {
        path: '',
        name: 'home',
        component: home
      },
      {
        path: 'about',
        name: 'about',
        component: about
      },
      {
        path: '*',
        name: 'error',
        component: error
      },
      {
        path: 'newslist',
        name: 'newslist',
        component: newslist
      },
      {
        path: 'newsdetail/:id',
        name: 'newsdetail',
        component: newsdetail
      },
      {
        path: 'fotolist',
        name: 'fotolist',
        component: fotolist
      },
      {
        path: 'videolist',
        name: 'videolist',
        component: videolist
      },
      {
        path: 'annolist',
        name: 'annolist',
        component: annolist
      },
      {
        path: 'leader',
        name: 'leader',
        component: leader
      },
      {
        path: 'page/:id',
        name: 'page',
        component: page
      },
      {
        path: 'application',
        name: 'application',
        component: application
      },
      {
        path: 'tenderlist',
        name: 'tenderlist',
        component: tenderlist
      },
      {
        path: 'contact',
        name: 'contact',
        component: contact
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  i18n.global.locale = to.params.lang;
  next();
})

export default router
