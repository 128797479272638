export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !! Uz"])},
  "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span>Ўзбекистон Республикаси энергетика вазирлиги ҳузуридаги</span><br /><span>БЮДЖЕТДАН ТАШҚАРИ ТАРМОҚЛАРАРО ЭНЕРГИЯНИ ТЕЖАШ ЖАМҒАРМАСИ</span>"])},
  "newstitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Янгиликлар"])},
  "newsall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Барча янгиликлар"])},
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["БИЗ ҲАҚИМИЗДА"])},
  "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Батафсил"])},
  "useful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фойдали ҳаволалар"])},
  "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тендерлар ва танловлар"])},
  "ads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ЭЪЛОНЛАР"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Барчасини кўриш"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сайт бўйлаб қидириш"])},
  "flogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Ўзбекистон Республикаси энергетика вазирлиги ҳузуридаги</p><p>БЮДЖЕТДАН ТАШҚАРИ ТАРМОҚЛАРАРО ЭНЕРГИЯНИ ТЕЖАШ ЖАМҒАРМАСИ</p>"])},
  "flink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Материаллардан фойдаланилганда, <a href='#'>www.iesf.uz</a> сайтига ҳавола қилиш мажбурийдир"])},
  "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Барча ҳуқуқлар ҳимояланган"])},
  "ctrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Диққат! Агар сиз матнда хато топсангиз, уни танланг ва маъмуриятни хабардор қилиш учун Ctrl + Enter ни босинг"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ишонч телефони:"])},
  "myphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон:"])},
  "work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Иш тартиби:"])},
  "lunch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тушлик:"])},
  "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Қабул кунлари:"])},
  "call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call-марказ:"])},
  "calltext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["электроэнергия қисми бўйича"])},
  "calltext2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["газ таъминоти бўйича"])},
  "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["09.00 дан 18.00 гача"])},
  "mydays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["душанба - жума: 9:00 - 18:00</br> тушлик вақти: 13:00 - 14:00</br> шанба, якшанба: дам олиш куни"])},
  "foto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фотогалерея"])},
  "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видеогалерея"])},
  "leader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Раҳбарият"])},
  "bio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Биография"])},
  "function": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Функциялари"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "fish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ФИШ"])},
  "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вилоят"])},
  "district": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Туман"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Манзил"])},
  "aphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Таъриф"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изоҳ"])},
  "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фойдаланувчи файли"])},
  "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Юбориш"])},
  "application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Murojaat formasi"])},
  "app-submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мурожаат юбориш"])},
  "app-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мурожаат ҳолатини текшириш"])},
  "app-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мурожаат рақами"])},
  "app-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код"])},
  "tender-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Барчаси"])},
  "tender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тендерлар"])},
  "competition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Танловлар"])},
  "mycopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нусха олиш"])},
  "mycopyed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нусха олинди"])},
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Боғланиш"])},
  "app-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ариза юборилган сана"])},
  "app-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ариза мавзуси"])},
  "app-status-get": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ариза статуси"])},
  "app-get": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["мурожаатингиз қабул қилинди"])},
  "app-save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мурожаат статусини билиш учун Рақам ва Код ни сақлаб қолинг"])},
  "app-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бош саҳифага ўтиш"])},
  "app_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рақам"])},
  "app_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код"])},
  "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Янги"])},
  "under_consideration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кўриб чиқилмоқда"])},
  "app-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кўриб чиқилди, жавоб кўрсатилган электрон манзилга юборилди"])},
  "app-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кўриб чиқилди, жавоб хати кўрсатилган манзилига юборилди"])},
  "bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мурожаат қилувчи Банк номи (Бош банк)"])},
  "clients_bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мижоз банк номи"])},
  "title_of_the_project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лойиҳа номи"])},
  "tin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банк СТИРи"])},
  "goal_of_credit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кредит мақсади"])},
  "bank_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банк манзили"])},
  "project_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лойиҳа суммаси"])},
  "requesting_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сўралаётган сумма"])},
  "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бошқа маълумотлар"])},
  "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мурожаат қилувчи банк телефон рақами"])},
  "certificate_of_bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лойиҳа ҳақида қисқа маълумот"])},
  "energy_efficiency_doc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Энергияни тежовчи қурилма, мослама ва материаллар бўйича маълумот"])},
  "other_docs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бошқалар"])},
  "short_info_about_project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банкнинг кредит ажратилишини тасдиқловчи маълумотномаси"])},
  "response_via_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Натижани манзилли почта орқали олиш"])},
  "response_via_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Натижани электрон почта орқали олиш"])},
  "contact_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100047, Ўзбекистон Республикаси, Тошкент ш, Истикбол кўчаси, 21"])},
  "email_bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Банк электрон почтаси E-mail"])},
  "legal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Юридик шахс ёки ЯТТ"])},
  "legal_tin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Юридик шахс ёки ЯТТ СТИРи"])},
  "legal_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Юридик шахс ёки ЯТТ манзили"])},
  "subway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Амир Темур бекати"])},
  "cars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№ 26, 102 (Мўлжал Тошкент Халқаро Вестминстер университети)"])},
  "captcha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Юқоридаги рақамни киритинг"])},
  "bayt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файл ҳажми 10Мб дан ошмаслиги керак"])}
}