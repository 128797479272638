<template>
  <myheader></myheader>

  <mymenu></mymenu>

  <section class="banner-section">
    <div class="banner-circle">
      <div class="banner-circle-in">
        <img src="../../public/img/circle.png" alt="circle" />
      </div>
    </div>
    <div class="container my-container">
      <div class="banner">
        <div class="owl-carousel owl-theme" v-if="slider.length > 0">
          <div class="item" v-for="item in slider" :key="item.id">
            <div class="container-fluid">
              <div class="row">
                <div class="col-xl-8 banner-col">
                  <div class="banner-img">
                    <router-link
                      :to="{ name: 'newsdetail', params: { id: item.id } }"
                    >
                      <div class="banner-img-in">
                        <img :src="item.image" />
                      </div>
                    </router-link>
                  </div>
                </div>
                <div class="col-xl-4 banner-col">
                  <div class="banner-right">
                    <div class="banner-title">
                      <router-link
                        :to="{ name: 'newsdetail', params: { id: item.id } }"
                      >
                        <span
                          v-if="
                            $i18n.locale == 'uz' &&
                            item.translations &&
                            item.translations.uz &&
                            item.translations.uz.headline
                          "
                          >{{ item.translations.uz.headline }}</span
                        >
                        <span
                          v-if="
                            $i18n.locale == 'ru' &&
                            item.translations &&
                            item.translations.ru &&
                            item.translations.ru.headline
                          "
                          >{{ item.translations.ru.headline }}</span
                        >
                        <span
                          v-if="
                            $i18n.locale == 'oz' &&
                            item.translations &&
                            item.translations.oz &&
                            item.translations.oz.headline
                          "
                          >{{ item.translations.oz.headline }}</span
                        >
                        <span
                          v-if="
                            $i18n.locale == 'en' &&
                            item.translations &&
                            item.translations.en &&
                            item.translations.en.headline
                          "
                          >{{ item.translations.en.headline }}</span
                        >
                      </router-link>
                    </div>
                    <div class="banner-description">
                      <span
                        v-if="
                          $i18n.locale == 'uz' &&
                          item.translations &&
                          item.translations.uz &&
                          item.translations.uz.description
                        "
                        >{{ item.translations.uz.description }}</span
                      >
                      <span
                        v-if="
                          $i18n.locale == 'ru' &&
                          item.translations &&
                          item.translations.ru &&
                          item.translations.ru.description
                        "
                        >{{ item.translations.ru.description }}</span
                      >
                      <span
                        v-if="
                          $i18n.locale == 'oz' &&
                          item.translations &&
                          item.translations.oz &&
                          item.translations.oz.description
                        "
                        >{{ item.translations.oz.description }}</span
                      >
                      <span
                        v-if="
                          $i18n.locale == 'en' &&
                          item.translations &&
                          item.translations.en &&
                          item.translations.en.description
                        "
                        >{{ item.translations.en.description }}</span
                      >
                    </div>
                    <div class="banner-link-share">
                      <div class="banner-link">
                        <router-link
                          :to="{ name: 'newsdetail', params: { id: item.id } }"
                        >
                          {{ $t("read") }}
                        </router-link>
                      </div>
                      <div class="banner-share">
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="news-section">
    <div class="container">
      <div class="heading">
        <div class="heading-left">
          <span>{{ $t("newstitle") }}</span>
        </div>
        <div class="heading-right">
          <router-link :to="{ name: 'newslist' }">
            <img src="img/right.png" />
            <span>{{ $t("newsall") }}</span>
          </router-link>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-6" v-if="home[0]">
          <div class="news-left">
            <router-link
              :to="{ name: 'newsdetail', params: { id: home[0].id } }"
            >
              <div class="news-left-in">
                <div class="news-left-img">
                  <img :src="home[0].image" />
                </div>
                <div class="news-left-text">
                  <div class="news-left-title">
                    <span
                      v-if="$i18n.locale == 'uz' && home[0].translations.uz"
                      >{{ home[0].translations.uz.headline }}</span
                    >
                    <span
                      v-if="$i18n.locale == 'ru' && home[0].translations.ru"
                      >{{ home[0].translations.ru.headline }}</span
                    >
                    <span
                      v-if="$i18n.locale == 'en' && home[0].translations.en"
                      >{{ home[0].translations.en.headline }}</span
                    >
                    <span
                      v-if="$i18n.locale == 'oz' && home[0].translations.oz"
                      >{{ home[0].translations.oz.headline }}</span
                    >
                  </div>
                  <div class="news-left-date">
                    <div class="news-left-time">
                      <img src="img/date.png" />
                      <span>{{ home[0].created_at }}</span>
                      <span> | </span>
                      <span>{{ home[0].time }}</span>
                    </div>
                    <div class="news-left-eye">
                      <img src="img/eyen.png" />
                      <span>{{ home[0].views_count }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>

        <div class="col-xl-3 col-md-6" v-if="home[1]">
          <div class="news-right">
            <div class="news-right-img">
              <router-link
                :to="{ name: 'newsdetail', params: { id: home[1].id } }"
              >
                <img :src="home[1].image" />
              </router-link>
            </div>
            <div class="news-right-category">
              <span>{{ $t("newstitle") }}</span>
            </div>
            <div class="news-right-title">
              <router-link
                :to="{ name: 'newsdetail', params: { id: home[1].id } }"
              >
                <span v-if="$i18n.locale == 'uz' && home[1].translations.uz">{{
                  home[1].translations.uz.headline
                }}</span>
                <span v-if="$i18n.locale == 'ru' && home[1].translations.ru">{{
                  home[1].translations.ru.headline
                }}</span>
                <span v-if="$i18n.locale == 'en' && home[1].translations.en">{{
                  home[1].translations.en.headline
                }}</span>
                <span v-if="$i18n.locale == 'oz' && home[1].translations.oz">{{
                  home[1].translations.oz.headline
                }}</span>
              </router-link>
            </div>
            <div class="news-right-date">
              <div class="news-right-time">
                <img src="img/date.png" />
                <span>{{ home[1].created_at }}</span>
                <span> | </span>
                <span>{{ home[1].time }}</span>
              </div>
              <div class="news-right-eye">
                <img src="img/eyen.png" />
                <span>{{ home[1].views_count }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6" v-if="home[2]">
          <div class="news-right">
            <div class="news-right-img">
              <router-link
                :to="{ name: 'newsdetail', params: { id: home[2].id } }"
              >
                <img :src="home[2].image" />
              </router-link>
            </div>
            <div class="news-right-category">
              <span>{{ $t("newstitle") }}</span>
            </div>
            <div class="news-right-title">
              <router-link
                :to="{ name: 'newsdetail', params: { id: home[2].id } }"
              >
                {{ home[2].headline }}
                <span v-if="$i18n.locale == 'uz' && home[2].translations.uz">{{
                  home[2].translations.uz.headline
                }}</span>
                <span v-if="$i18n.locale == 'ru' && home[2].translations.ru">{{
                  home[2].translations.ru.headline
                }}</span>
                <span v-if="$i18n.locale == 'en' && home[2].translations.en">{{
                  home[2].translations.en.headline
                }}</span>
                <span v-if="$i18n.locale == 'oz' && home[2].translations.oz">{{
                  home[2].translations.oz.headline
                }}</span>
              </router-link>
            </div>
            <div class="news-right-date">
              <div class="news-right-time">
                <img src="img/date.png" />
                <span>{{ home[2].created_at }}</span>
                <span> | </span>
                <span>{{ home[2].time }}</span>
              </div>
              <div class="news-right-eye">
                <img src="img/eyen.png" />
                <span>{{ home[2].views_count }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="about-section">
    <div class="container" v-if="about">
      <div class="row">
        <div class="col-lg-6">
          <div class="about-left">
            <div class="simple">
              <div class="heading">
                <div class="heading-left">
                  <span>{{ $t("about") }}</span>
                </div>
              </div>
              <div
                class="about-left-text"
                v-if="
                  $i18n.locale == 'uz' &&
                  about.translations &&
                  about.translations.uz
                "
                v-html="about.translations.uz.about_us_text"
              ></div>
              <div
                class="about-left-text"
                v-if="
                  $i18n.locale == 'ru' &&
                  about.translations &&
                  about.translations.uz
                "
                v-html="about.translations.ru.about_us_text"
              ></div>
              <div
                class="about-left-text"
                v-if="
                  $i18n.locale == 'en' &&
                  about.translations &&
                  about.translations.uz
                "
                v-html="about.translations.en.about_us_text"
              ></div>
              <div
                class="about-left-text"
                v-if="
                  $i18n.locale == 'oz' &&
                  about.translations &&
                  about.translations.uz
                "
                v-html="about.translations.oz.about_us_text"
              ></div>
              <div class="about-left-link">
                <a href="#">
                  <img src="img/right.png" />
                  <span>{{ $t("read") }}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="about-right">
            <div class="about-two">
              <img src="img/about-back.png" />
            </div>
            <div class="about-one">
              <img :src="about.image" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="media-section">
    <ul class="media-ul" v-if="video.length > 0">
      <li v-for="item in video" :key="item.id">
        <div class="media-out">
          <a
            v-if="item.youtube_url"
            data-fancybox
            :href="item.youtube_url"
            :data-caption="item.name"
          >
            <div class="media-in play">
              <img :src="item.cover_image" />
            </div>
          </a>

          <a
            v-if="item.utube_url"
            data-fancybox
            data-type="iframe"
            :data-src="item.utube_url"
            href="javascript:;"
          >
            <div class="media-in play">
              <img :src="item.cover_image" alt="video" />
            </div>
          </a>

          <a
            v-if="item.video_file"
            data-fancybox
            :href="item.video_file"
            :data-caption="item.name"
          >
            <div class="media-in play">
              <img :src="item.cover_image" />
            </div>
          </a>
        </div>
      </li>

      <li v-for="(item, index) in myimage" :key="item.id">
        <div class="media-out">
          <button
            type="button"
            class="media-in"
            data-toggle="modal"
            :data-target="'#id' + index"
          >
            <img v-if="item.event_image[0] && item.event_image[0].image_file" :src="item.event_image[0].image_file" alt="image" />
          </button>
        </div>

        <div
          class="modal fade"
          :id="'id' + index"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            class="
              modal-dialog
              modal-xl
              modal-dialog-scrollable
              modal-dialog-centered
            "
          >
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  <span
                    v-if="
                      $i18n.locale == 'uz' &&
                      item.translations &&
                      item.translations.uz &&
                      item.translations.uz.title
                    "
                    >{{ item.translations.uz.title }}</span
                  >
                  <span
                    v-if="
                      $i18n.locale == 'ru' &&
                      item.translations &&
                      item.translations.ru &&
                      item.translations.ru.title
                    "
                    >{{ item.translations.ru.title }}</span
                  >
                  <span
                    v-if="
                      $i18n.locale == 'oz' &&
                      item.translations &&
                      item.translations.oz &&
                      item.translations.oz.title
                    "
                    >{{ item.translations.oz.title }}</span
                  >
                  <span
                    v-if="
                      $i18n.locale == 'en' &&
                      item.translations &&
                      item.translations.en &&
                      item.translations.en.title
                    "
                    >{{ item.translations.en.title }}</span
                  >
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="container">
                  <div class="row">
                    <div
                      class="col-md-4"
                      v-for="item in item.event_image"
                      :key="item.id"
                    >
                      <div class="media-out mb-4">
                        <a data-fancybox="images" :href="item.image_file">
                          <div class="media-in">
                            <img :src="item.image_file" />
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <div class="foto-all">
      <div class="container">
        <router-link :to="{ name: 'fotolist' }">{{ $t("foto") }}</router-link>
        <span> | </span>
        <router-link :to="{ name: 'videolist' }">{{ $t("video") }}</router-link>
      </div>
    </div>
  </section>

  <section class="announce-section">
    <div class="container" v-if="anno.length > 0">
      <div class="heading">
        <div class="heading-left">
          <span>{{ $t("ads") }}</span>
        </div>
        <div class="heading-right">
          <router-link :to="{ name: 'annolist' }">
            <img src="img/right.png" />
            <span>{{ $t("all") }}</span>
          </router-link>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-4 col-md-6" v-for="item in anno" :key="item.id">
          <div class="announce">
            <div class="announce-img">
              <router-link
                :to="{ name: 'newsdetail', params: { id: item.id } }"
              >
                <div class="announce-img-in">
                  <img :src="item.image" />
                </div>
              </router-link>
            </div>
            <div class="anno-card">
              <div class="anno-title">
                <router-link
                  :to="{ name: 'newsdetail', params: { id: item.id } }"
                >
                  <span
                    v-if="
                      $i18n.locale == 'uz' &&
                      item.translations &&
                      item.translations.uz &&
                      item.translations.uz.headline
                    "
                    >{{ item.translations.uz.headline }}</span
                  >
                  <span
                    v-if="
                      $i18n.locale == 'ru' &&
                      item.translations &&
                      item.translations.ru &&
                      item.translations.ru.headline
                    "
                    >{{ item.translations.ru.headline }}</span
                  >
                  <span
                    v-if="
                      $i18n.locale == 'oz' &&
                      item.translations &&
                      item.translations.oz &&
                      item.translations.oz.headline
                    "
                    >{{ item.translations.oz.headline }}</span
                  >
                  <span
                    v-if="
                      $i18n.locale == 'en' &&
                      item.translations &&
                      item.translations.en &&
                      item.translations.en.headline
                    "
                    >{{ item.translations.en.headline }}</span
                  >
                </router-link>
              </div>
              <div class="news-right-date">
                <div class="news-right-time">
                  <img src="img/date.png" />
                  <span>{{ item.created_at }}</span>
                  <span> | </span>
                  <span>{{ item.time }}</span>
                </div>
                <div class="news-right-eye">
                  <img src="img/eyen.png" />
                  <span>{{ item.views_count }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="choice-section">
    <div class="container" v-if="mytender.length > 0">
      <div class="heading">
        <div class="heading-left">
          <span>{{ $t("choose") }}</span>
        </div>
        <div class="heading-right">
          <router-link :to="{ name: 'tenderlist' }">
            <img src="img/right.png" />
            <span>{{ $t("all") }}</span>
          </router-link>
        </div>
      </div>

      <div class="choose-tab">
        <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
          <li class="nav-item" role="presentation">
            <a
              class="nav-link active"
              id="pills-home-tab"
              data-toggle="pill"
              href="#pills-home"
              role="tab"
              aria-controls="pills-home"
              aria-selected="true"
              >{{ $t("tender-all") }}</a
            >
          </li>
          <li class="nav-item" role="presentation">
            <a
              class="nav-link"
              id="pills-profile-tab"
              data-toggle="pill"
              href="#pills-profile"
              role="tab"
              aria-controls="pills-profile"
              aria-selected="false"
              >{{ $t("tender") }}</a
            >
          </li>
          <li class="nav-item" role="presentation">
            <a
              class="nav-link"
              id="pills-contact-tab"
              data-toggle="pill"
              href="#pills-contact"
              role="tab"
              aria-controls="pills-contact"
              aria-selected="false"
              >{{ $t("competition") }}</a
            >
          </li>
        </ul>
        <div class="tab-content" id="pills-tabContent">
          <div
            class="tab-pane fade show active"
            id="pills-home"
            role="tabpanel"
            aria-labelledby="pills-home-tab"
          >
            <div class="row">
              <div class="col-lg-6" v-for="item in mytender" :key="item.id">
                <div class="choice">
                  <router-link
                    :to="{ name: 'newsdetail', params: { id: item.id } }"
                  >
                    <div class="choice-in">
                      <div class="choice-title">
                        <span
                          v-if="
                            $i18n.locale == 'uz' &&
                            item.translations &&
                            item.translations.uz &&
                            item.translations.uz.headline
                          "
                          >{{ item.translations.uz.headline }}</span
                        >
                        <span
                          v-if="
                            $i18n.locale == 'ru' &&
                            item.translations &&
                            item.translations.ru &&
                            item.translations.ru.headline
                          "
                          >{{ item.translations.ru.headline }}</span
                        >
                        <span
                          v-if="
                            $i18n.locale == 'oz' &&
                            item.translations &&
                            item.translations.oz &&
                            item.translations.oz.headline
                          "
                          >{{ item.translations.oz.headline }}</span
                        >
                        <span
                          v-if="
                            $i18n.locale == 'en' &&
                            item.translations &&
                            item.translations.en &&
                            item.translations.en.headline
                          "
                          >{{ item.translations.en.headline }}</span
                        >
                      </div>
                      <div class="news-right-date">
                        <div class="news-right-time">
                          <img src="img/date.png" />
                          <span>{{ item.created_at }}</span>
                          <span> | </span>
                          <span>{{ item.time }}</span>
                        </div>
                        <div class="news-right-eye">
                          <img src="img/eyen.png" />
                          <span>{{ item.views_count }}</span>
                        </div>
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="pills-profile"
            role="tabpanel"
            aria-labelledby="pills-profile-tab"
          >
            <div class="row">
              <div class="col-lg-6" v-for="item in tendertender" :key="item.id">
                <div class="choice">
                  <router-link
                    :to="{ name: 'newsdetail', params: { id: item.id } }"
                  >
                    <div class="choice-in">
                      <div class="choice-title">
                        <span
                          v-if="
                            $i18n.locale == 'uz' &&
                            item.translations &&
                            item.translations.uz &&
                            item.translations.uz.headline
                          "
                          >{{ item.translations.uz.headline }}</span
                        >
                        <span
                          v-if="
                            $i18n.locale == 'ru' &&
                            item.translations &&
                            item.translations.ru &&
                            item.translations.ru.headline
                          "
                          >{{ item.translations.ru.headline }}</span
                        >
                        <span
                          v-if="
                            $i18n.locale == 'oz' &&
                            item.translations &&
                            item.translations.oz &&
                            item.translations.oz.headline
                          "
                          >{{ item.translations.oz.headline }}</span
                        >
                        <span
                          v-if="
                            $i18n.locale == 'en' &&
                            item.translations &&
                            item.translations.en &&
                            item.translations.en.headline
                          "
                          >{{ item.translations.en.headline }}</span
                        >
                      </div>
                      <div class="news-right-date">
                        <div class="news-right-time">
                          <img src="img/date.png" />
                          <span>{{ item.created_at }}</span>
                          <span> | </span>
                          <span>{{ item.time }}</span>
                        </div>
                        <div class="news-right-eye">
                          <img src="img/eyen.png" />
                          <span>{{ item.views_count }}</span>
                        </div>
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="pills-contact"
            role="tabpanel"
            aria-labelledby="pills-contact-tab"
          >
            <div class="row">
              <div class="col-lg-6" v-for="item in competition" :key="item.id">
                <div class="choice">
                  <router-link
                    :to="{ name: 'newsdetail', params: { id: item.id } }"
                  >
                    <div class="choice-in">
                      <div class="choice-title">
                        <span
                          v-if="
                            $i18n.locale == 'uz' &&
                            item.translations &&
                            item.translations.uz &&
                            item.translations.uz.headline
                          "
                          >{{ item.translations.uz.headline }}</span
                        >
                        <span
                          v-if="
                            $i18n.locale == 'ru' &&
                            item.translations &&
                            item.translations.ru &&
                            item.translations.ru.headline
                          "
                          >{{ item.translations.ru.headline }}</span
                        >
                        <span
                          v-if="
                            $i18n.locale == 'oz' &&
                            item.translations &&
                            item.translations.oz &&
                            item.translations.oz.headline
                          "
                          >{{ item.translations.oz.headline }}</span
                        >
                        <span
                          v-if="
                            $i18n.locale == 'en' &&
                            item.translations &&
                            item.translations.en &&
                            item.translations.en.headline
                          "
                          >{{ item.translations.en.headline }}</span
                        >
                      </div>
                      <div class="news-right-date">
                        <div class="news-right-time">
                          <img src="img/date.png" />
                          <span>{{ item.created_at }}</span>
                          <span> | </span>
                          <span>{{ item.time }}</span>
                        </div>
                        <div class="news-right-eye">
                          <img src="img/eyen.png" />
                          <span>{{ item.views_count }}</span>
                        </div>
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="useful-section">
    <div class="container">
      <div class="heading">
        <div class="heading-left">
          <span>{{ $t("useful") }}</span>
        </div>
      </div>
      <div class="owl-useful">
        <div class="owl-carousel owl-theme" v-if="useful.length > 0">
          <div class="item" v-for="item in useful" :key="item.id">
            <div class="useful">
              <a :href="item.url" target="_blank">
                <div class="useful-in">
                  <div class="useful-icon">
                    <img :src="item.icon" />
                  </div>
                  <div class="useful-text">
                    <p
                      v-if="
                        $i18n.locale == 'uz' &&
                        item.translations &&
                        item.translations.uz &&
                        item.translations.uz.name
                      "
                    >
                      {{ item.translations.uz.name }}
                    </p>
                    <p
                      v-if="
                        $i18n.locale == 'ru' &&
                        item.translations &&
                        item.translations.ru &&
                        item.translations.ru.name
                      "
                    >
                      {{ item.translations.ru.name }}
                    </p>
                    <p
                      v-if="
                        $i18n.locale == 'oz' &&
                        item.translations &&
                        item.translations.oz &&
                        item.translations.oz.name
                      "
                    >
                      {{ item.translations.oz.name }}
                    </p>
                    <p
                      v-if="
                        $i18n.locale == 'en' &&
                        item.translations &&
                        item.translations.en &&
                        item.translations.en.name
                      "
                    >
                      {{ item.translations.en.name }}
                    </p>
                    <p>{{ item.url }}</p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <myfooter></myfooter>

  <div class="test">
    <marquee behavior="alternate"
      >Sayt test rejimida ishlamoqda / Сайт тест режмида ишламоқда / The site is
      running in test mode / Сайт работает в тестовом режиме
    </marquee>
  </div>
</template>

<script>
import axios from "axios";
import myheader from "../components/myheader.vue";
import mymenu from "../components/mymenu.vue";
import myfooter from "../components/myfooter.vue";

export default {
  name: "Home",
  components: {
    myheader: myheader,
    mymenu: mymenu,
    myfooter: myfooter,
  },
  data() {
    return {
      myurl: process.env.VUE_APP_API_BASE_URL,
      url: process.env.VUE_APP_API_BASE_URL + `n/news/`,
      urluseful: process.env.VUE_APP_API_BASE_URL + `cms/useful_links/`,
      urlanno: process.env.VUE_APP_API_BASE_URL + `n/announcements/`,
      urlabout: process.env.VUE_APP_API_BASE_URL + `cms/owner_data/`,
      urlimage: process.env.VUE_APP_API_BASE_URL + `cms/events/`,
      urlvideo: process.env.VUE_APP_API_BASE_URL + `cms/videos/`,
      urltender: process.env.VUE_APP_API_BASE_URL + `n/tenders/`,
      news: [],
      anno: [],
      useful: [],
      about: [],
      image: [],
      video: [],
      tender: [],
    };
  },
  mounted() {
    this.getdata();
  },
  methods: {
    getdata() {
      axios
        .get(this.url)
        .then((response) => {
          this.news = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      axios
        .get(this.urlabout)
        .then((response) => {
          this.about = response.data[0];
        })
        .catch((error) => {
          console.log(error);
        });
      axios
        .get(this.urlvideo)
        .then((response) => {
          this.video = response.data.slice(-3);
        })
        .catch((error) => {
          console.log(error);
        });
      axios
        .get(this.urlimage)
        .then((response) => {
          this.image = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      axios
        .get(this.urluseful)
        .then((response) => {
          this.useful = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      axios
        .get(this.urlanno)
        .then((response) => {
          this.anno = response.data.slice(-3);
        })
        .catch((error) => {
          console.log(error);
        });
      axios
        .get(this.urltender)
        .then((response) => {
          this.tender = response.data.slice(-4);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {
    slider: function () {
      return this.news.filter((item) => {
          return item.show_on_slider;
        });
    },
    home: function () {
      return this.news.filter((item) => {
        return item.show_on_home_page;
      });
    },
    myimage: function () {
      return this.image.slice(0, 4);
    },
    mytender: function () {
      return this.tender.filter((item) => {
        return item.show_on_home_page;
      });
    },
    tendertender: function () {
      return this.tender.filter((item) => {
        return item.show_on_home_page && item.news_type == "tender";
      });
    },
    competition: function () {
      return this.tender.filter((item) => {
        return item.show_on_home_page && item.news_type == "competition";
      });
    },
  },
};
</script>
