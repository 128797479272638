export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !! Ru"])},
  "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class='d-none'></span><br  class='d-none' /><span>ВНЕБЮДЖЕТНЫЙ МЕЖОТРАСЛЕВОЙ ФОНД ЭНЕРГОСБЕРЕЖЕНИЯ</span><br /><span>при Министерстве Энергетики Республики Узбекистан</span>"])},
  "newstitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новости"])},
  "newsall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все новости"])},
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["О НАС"])},
  "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подробнее"])},
  "useful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ПОЛЕЗНЫЕ ССЫЛКИ"])},
  "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ТЕНДЕРЫ И КОНКУРСЫ"])},
  "ads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ОБЪЯВЛЕНИЯ"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Увидеть их всех"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Искать по сайту"])},
  "flogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>ВНЕБЮДЖЕТНЫЙ МЕЖОТРАСЛЕВОЙ ФОНД ЭНЕРГОСБЕРЕЖЕНИЯ</p><p>при Министерстве Энергетики Республики Узбекистан</p>"])},
  "flink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При использовании материалов ссылка на <a href='#'>www.iesf.uz</a> обязательна."])},
  "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все права защищены"])},
  "ctrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внимание! Если вы обнаружили ошибку в тексте, выделите ее и нажмите Ctrl + Enter, чтобы уведомить администрацию"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон доверия:"])},
  "myphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон:"])},
  "work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Режим работы:"])},
  "lunch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обед:"])},
  "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дни приёма:"])},
  "call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Колл центр:"])},
  "calltext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["по части электроэнергии"])},
  "calltext2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["по части газоснабжения"])},
  "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c 09.00 до 18.00"])},
  "mydays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["понедельник - пятница: 9:00 - 18:00<br/> обеденный перерыв: 13:00 - 14:00<br/> суббота, воскресенье: выходной"])},
  "foto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фотогалерея"])},
  "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видеогалерея"])},
  "leader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Руководство"])},
  "bio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Биография"])},
  "function": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Функции"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "fish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ФИО"])},
  "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Область"])},
  "district": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Район"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес"])},
  "aphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Описание"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комментарий"])},
  "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Файл пользователя"])},
  "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить"])},
  "application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Форма обращения"])},
  "app-submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить заявку"])},
  "app-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверить состояние обращения"])},
  "app-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер обращения"])},
  "app-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код"])},
  "tender-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все"])},
  "tender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тендеры"])},
  "competition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Конкурсы"])},
  "mycopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скопировать"])},
  "mycopyed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скопировано"])},
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Связь"])},
  "app-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата подачи заявки"])},
  "app-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тема заявки"])},
  "app-status-get": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус заявки"])},
  "app-get": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ваша заявка принята"])},
  "app-save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохраните Номер и Код заявки, чтобы узнать статус заявки"])},
  "app-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Переход) на главную страницу"])},
  "app_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер"])},
  "app_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код"])},
  "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новое"])},
  "under_consideration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На рассмотрении"])},
  "app-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рассмотрено, ответ направлен на указанную эл.почту"])},
  "app-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рассмотрено, ответное письмо направлено по указанному адресу"])},
  "bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название банка-заявителя (Главный банк)"])},
  "clients_bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наименование клиента банка"])},
  "title_of_the_project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Наименование проекта"])},
  "tin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИНН банка"])},
  "goal_of_credit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цель кредита"])},
  "bank_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес банка"])},
  "project_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма проекта"])},
  "requesting_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запрашиваемая сумма"])},
  "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дополнительная информация"])},
  "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер телефона банка-заявителя"])},
  "certificate_of_bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Краткая информация о проекте"])},
  "energy_efficiency_doc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Информация по энергоэффективному оборудованию, установке и материалам"])},
  "other_docs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Другое"])},
  "short_info_about_project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Справка банка об одобрении выделения кредита"])},
  "response_via_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получить результат по почте"])},
  "response_via_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получит результат по электронной почте"])},
  "contact_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100047, Республика Узбекистан, г. Ташкент, ул. Истикбол, 21 "])},
  "email_bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Электронная почта банка"])},
  "legal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Юридическое лицо или ИП"])},
  "legal_tin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИНН юридического лица или ИП"])},
  "legal_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес юридического лица или ИП"])},
  "subway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["станция Амира Темура"])},
  "cars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№ 26, 102 (Ориентир Международный Вестминстерский Университет)"])},
  "captcha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите число выше"])},
  "bayt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Размер файла не должен превышать 10Mб"])}
}