<template>
  <div class="dropdown-menu" aria-labelledby="navbarDropdown">
    <div v-for="item in datatree" :key="item.id" class="sub">
      
      <router-link v-if="item.children.length === 0 && !item.news_id" class="dropdown-item" :to="{ name : item.name }">
        <span v-if="$i18n.locale == 'uz' && item.translations && item.translations.uz && item.translations.uz.title">{{ item.translations.uz.title }}</span>
        <span v-if="$i18n.locale == 'ru' && item.translations && item.translations.ru && item.translations.ru.title">{{ item.translations.ru.title }}</span>
        <span v-if="$i18n.locale == 'oz' && item.translations && item.translations.oz && item.translations.oz.title">{{ item.translations.oz.title }}</span>
        <span v-if="$i18n.locale == 'en' && item.translations && item.translations.en && item.translations.en.title">{{ item.translations.en.title }}</span>
      </router-link>
      
      <router-link v-if="item.children.length === 0 && item.news_id" class="dropdown-item" :to="{ name : 'page', params: { id: item.news_id } }">
        <span v-if="$i18n.locale == 'uz' && item.translations && item.translations.uz && item.translations.uz.title">{{ item.translations.uz.title }}</span>
        <span v-if="$i18n.locale == 'ru' && item.translations && item.translations.ru && item.translations.ru.title">{{ item.translations.ru.title }}</span>
        <span v-if="$i18n.locale == 'oz' && item.translations && item.translations.oz && item.translations.oz.title">{{ item.translations.oz.title }}</span>
        <span v-if="$i18n.locale == 'en' && item.translations && item.translations.en && item.translations.en.title">{{ item.translations.en.title }}</span>
      </router-link>
      
      <a v-if="item.children.length > 0" class="dropdown-item dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" role="button">
        <span v-if="$i18n.locale == 'uz' && item.translations && item.translations.uz && item.translations.uz.title">{{ item.translations.uz.title }}</span>
        <span v-if="$i18n.locale == 'ru' && item.translations && item.translations.ru && item.translations.ru.title">{{ item.translations.ru.title }}</span>
        <span v-if="$i18n.locale == 'oz' && item.translations && item.translations.oz && item.translations.oz.title">{{ item.translations.oz.title }}</span>
        <span v-if="$i18n.locale == 'en' && item.translations && item.translations.en && item.translations.en.title">{{ item.translations.en.title }}</span>
      </a>
      <treemenu v-if="item.children.length > 0" :datatree="item.children"></treemenu>
    </div>
  </div>
</template>

<script>
export default {
  name: "treemenu",
  props: ["datatree"],
};
</script>
