<template>
  <myheader></myheader>
  <mymenu></mymenu>
  <banner :title="$t('leader')"></banner>

  <section class="page">
    <div class="container">
      <div class="row">
        <div class="col-xl-12">

          <div class="leader" v-for="( item, index ) in lead" :key="item.id">
            <div class="row">
              <div class="col-xl-3 col-md-4">
                <div class="leader-img">
                  <img
                    :src="item.image"
                    alt="leader"
                  />
                </div>
              </div>
              <div class="col-xl-9 col-md-8">
                <div class="leader-content">
                  <div class="leader-position">
                    <span v-if="$i18n.locale == 'uz' && item.translations && item.translations.uz && item.translations.uz.position">{{ item.translations.uz.position }}</span>
                    <span v-if="$i18n.locale == 'ru' && item.translations && item.translations.ru && item.translations.ru.position">{{ item.translations.ru.position }}</span>
                    <span v-if="$i18n.locale == 'oz' && item.translations && item.translations.oz && item.translations.oz.position">{{ item.translations.oz.position }}</span>
                    <span v-if="$i18n.locale == 'en' && item.translations && item.translations.en && item.translations.en.position">{{ item.translations.en.position }}</span>
                  </div>
                  <div class="leader-name">
                    
                    <span v-if="$i18n.locale == 'uz' && item.translations && item.translations.uz && item.translations.uz.last_name">{{ item.translations.uz.last_name }}</span>
                    <span v-if="$i18n.locale == 'ru' && item.translations && item.translations.ru && item.translations.ru.last_name">{{ item.translations.ru.last_name }}</span>
                    <span v-if="$i18n.locale == 'oz' && item.translations && item.translations.oz && item.translations.oz.last_name">{{ item.translations.oz.last_name }}</span>
                    <span v-if="$i18n.locale == 'en' && item.translations && item.translations.en && item.translations.en.last_name">{{ item.translations.en.last_name }}</span>
                    <span>&nbsp;</span>
                    <span v-if="$i18n.locale == 'uz' && item.translations && item.translations.uz && item.translations.uz.first_name">{{ item.translations.uz.first_name }}</span>
                    <span v-if="$i18n.locale == 'ru' && item.translations && item.translations.ru && item.translations.ru.first_name">{{ item.translations.ru.first_name }}</span>
                    <span v-if="$i18n.locale == 'oz' && item.translations && item.translations.oz && item.translations.oz.first_name">{{ item.translations.oz.first_name }}</span>
                    <span v-if="$i18n.locale == 'en' && item.translations && item.translations.en && item.translations.en.first_name">{{ item.translations.en.first_name }}</span>
                    <span>&nbsp;</span>
                    <span v-if="$i18n.locale == 'uz' && item.translations && item.translations.uz && item.translations.uz.patronymic">{{ item.translations.uz.patronymic }}</span>
                    <span v-if="$i18n.locale == 'ru' && item.translations && item.translations.ru && item.translations.ru.patronymic">{{ item.translations.ru.patronymic }}</span>
                    <span v-if="$i18n.locale == 'oz' && item.translations && item.translations.oz && item.translations.oz.patronymic">{{ item.translations.oz.patronymic }}</span>
                    <span v-if="$i18n.locale == 'en' && item.translations && item.translations.en && item.translations.en.patronymic">{{ item.translations.en.patronymic }}</span>

                  </div>
                  <div class="leader-day">
                    <div class="row">
                      <div class="col-xl-4 col-lg-6">
                        <div class="leader-phone phone">
                          <p class="leader-left">{{ $t('myphone') }}</p>
                          <p class="leader-right">{{ item.phone_number }}</p>
                        </div>
                      </div>
                      <div class="col-xl-4 col-lg-6">
                        <div class="leader-phone l-email">
                          <p class="leader-left">{{ $t('email') }}</p>
                          <p class="leader-right">{{ item.email }}</p>
                        </div>
                      </div>
                      
                      <div class="col-xl-4 col-lg-6">
                        <div class="leader-phone day">
                          <p class="leader-left">{{ $t('days') }}</p>
                          <p class="leader-right">
                            <span v-if="$i18n.locale == 'uz' && item.translations && item.translations.uz && item.translations.uz.visiting_days">{{ item.translations.uz.visiting_days }}</span>
                            <span v-if="$i18n.locale == 'ru' && item.translations && item.translations.ru && item.translations.ru.visiting_days">{{ item.translations.ru.visiting_days }}</span>
                            <span v-if="$i18n.locale == 'oz' && item.translations && item.translations.oz && item.translations.oz.visiting_days">{{ item.translations.oz.visiting_days }}</span>
                            <span v-if="$i18n.locale == 'en' && item.translations && item.translations.en && item.translations.en.visiting_days">{{ item.translations.en.visiting_days }}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="leader-tab">
                    <div class="leader-tab-nav">
                      <button
                        @click="tabonei(index)"
                        :class="tabone[index] ? 'active' : ''"
                      >
                        {{ $t('bio') }}
                      </button>
                      <button
                        @click="tabtwoi(index)"
                        :class="tabtwo[index] ? 'active' : ''"
                      >
                        {{ $t('function') }}
                      </button>
                    </div>
                    <div class="leader-tab-content">
                      <div class="leader-in" v-show="tabone[index]">
                        <span v-if="$i18n.locale == 'uz' && item.translations && item.translations.uz && item.translations.uz.biography">{{ item.translations.uz.biography }}</span>
                        <span v-if="$i18n.locale == 'ru' && item.translations && item.translations.ru && item.translations.ru.biography">{{ item.translations.ru.biography }}</span>
                        <span v-if="$i18n.locale == 'oz' && item.translations && item.translations.oz && item.translations.oz.biography">{{ item.translations.oz.biography }}</span>
                        <span v-if="$i18n.locale == 'en' && item.translations && item.translations.en && item.translations.en.biography">{{ item.translations.en.biography }}</span>
                      </div>
                      <div class="leader-in" v-show="tabtwo[index]">
                        <span v-if="$i18n.locale == 'uz' && item.translations && item.translations.uz && item.translations.uz.responsibilities">{{ item.translations.uz.responsibilities }}</span>
                        <span v-if="$i18n.locale == 'ru' && item.translations && item.translations.ru && item.translations.ru.responsibilities">{{ item.translations.ru.responsibilities }}</span>
                        <span v-if="$i18n.locale == 'oz' && item.translations && item.translations.oz && item.translations.oz.responsibilities">{{ item.translations.oz.responsibilities }}</span>
                        <span v-if="$i18n.locale == 'en' && item.translations && item.translations.en && item.translations.en.responsibilities">{{ item.translations.en.responsibilities }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </section>

  <myfooter></myfooter>
</template>

<script>
import axios from "axios";
import banner from "../components/banner.vue";
import Myfooter from "../components/myfooter.vue";
import myheader from "../components/myheader.vue";
import mymenu from "../components/mymenu.vue";

export default {
  name: "leader",
  components: { myheader, mymenu, banner, Myfooter },
  data() {
    return {
      tabone: [false],
      tabtwo: [false],
      urlleader: process.env.VUE_APP_API_BASE_URL + `leaders/`,
      lead: [],
    };
  },
  methods: {
    tabonei(i) {
      this.tabone[i] = !this.tabone[i];
      this.tabtwo[i] = false;
    },
    tabtwoi(i) {
      this.tabtwo[i] = !this.tabtwo[i];
      this.tabone[i] = false;
    },
  },
  mounted() {
    axios
      .get(this.urlleader)
      .then((response) => {
        this.lead = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style scoped>
.leader-img {
  margin-top: 15px;
}
.leader-img img {
  width: 100%;
  border-radius: 5px;
  height: 280px;
  object-fit: cover;
  object-position: top;
}
.leader-content {
  margin-top: 15px;
}
.leader-position span {
  font-weight: 600;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.leader-name {
  margin-top: 10px;
}
.leader-name span {
  font-weight: 700;
  font-size: 22px;
}
.leader-phone {
  padding-left: 45px;
  margin-top: 15px;
}
.leader-phone .leader-left {
  margin-bottom: 0;
  font-weight: 700;
}
.leader-phone .leader-right {
  margin-top: 0;
}
.phone {
  position: relative;
}
.phone:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 45px;
  height: 50px;
  background-image: url("../../public/img/phone0.png");
  background-repeat: no-repeat;
  background-position: center left;
}
.day {
  position: relative;
}
.day:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 45px;
  height: 50px;
  background-image: url("../../public/img/time.png");
  background-repeat: no-repeat;
  background-position: center left;
}
.l-email {
  position: relative;
}
.l-email:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 45px;
  height: 50px;
  background-image: url("../../public/img/email.svg");
  background-repeat: no-repeat;
  background-position: center left;
}
.leader {
  margin-top: 15px;
}
.leader-tab-nav button {
  display: inline-block;
  border-radius: 3px;
  line-height: 50px;
  padding-left: 20px;
  padding-right: 20px;
  letter-spacing: 0.27px;
  font-size: 18px;
  margin-top: 10px;
  background-color: #e9f4fd;
  margin-right: 10px;
  color: #005695;
  border: none;
}
.leader-tab-nav button.active {
  color: rgba(255, 255, 255, 1);
  background-color: rgba(41, 144, 229, 1);
}
.leader-in {
  padding: 30px;
  background-color: rgb(233 244 253);
}
@media (min-width: 768px) {
  .leader-in {
    margin-top: 30px;
  }
}
@media (max-width: 767px) {
  .leader-in {
    margin-top: 15px;
  }
}
</style>