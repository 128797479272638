<template>
  <myheader></myheader>
  <mymenu></mymenu>
  <banner :title="mytitle"></banner>
  <section class="page">
    <div class="container">
      <div class="row">
        <div class="col-md-8">
          <div class="left">
            <div class="p-image" v-if="post.image">
              <img :src="post.image" alt="image">
            </div>
            <div class="p-title" v-show="false">
              <span
                v-if="
                  $i18n.locale == 'uz' &&
                  post.translations &&
                  post.translations.uz &&
                  post.translations.uz.headline
                "
                >{{ post.translations.uz.headline }}</span
              >
              <span
                v-if="
                  $i18n.locale == 'ru' &&
                  post.translations &&
                  post.translations.ru &&
                  post.translations.ru.headline
                "
                >{{ post.translations.ru.headline }}</span
              >
              <span
                v-if="
                  $i18n.locale == 'oz' &&
                  post.translations &&
                  post.translations.oz &&
                  post.translations.oz.headline
                "
                >{{ post.translations.oz.headline }}</span
              >
              <span
                v-if="
                  $i18n.locale == 'en' &&
                  post.translations &&
                  post.translations.en &&
                  post.translations.en.headline
                "
                >{{ post.translations.en.headline }}</span
              >
            </div>
            <div class="p-description">
              <span
                v-if="
                  $i18n.locale == 'uz' &&
                  post.translations &&
                  post.translations.uz &&
                  post.translations.uz.description
                "
                >{{ post.translations.uz.description }}</span
              >
              <span
                v-if="
                  $i18n.locale == 'ru' &&
                  post.translations &&
                  post.translations.ru &&
                  post.translations.ru.description
                "
                >{{ post.translations.ru.description }}</span
              >
              <span
                v-if="
                  $i18n.locale == 'oz' &&
                  post.translations &&
                  post.translations.oz &&
                  post.translations.oz.description
                "
                >{{ post.translations.oz.description }}</span
              >
              <span
                v-if="
                  $i18n.locale == 'en' &&
                  post.translations &&
                  post.translations.en &&
                  post.translations.en.description
                "
                >{{ post.translations.en.description }}</span
              >
            </div>
            <div
              class="p-content"
              v-if="
                $i18n.locale == 'uz' &&
                post.translations &&
                post.translations.uz &&
                post.translations.uz.content
              "
              v-html="post.translations.uz.content"
            ></div>
            <div
              class="p-content"
              v-if="
                $i18n.locale == 'ru' &&
                post.translations &&
                post.translations.ru &&
                post.translations.ru.content
              "
              v-html="post.translations.ru.content"
            ></div>
            <div
              class="p-content"
              v-if="
                $i18n.locale == 'oz' &&
                post.translations &&
                post.translations.oz &&
                post.translations.uz.content
              "
              v-html="post.translations.oz.content"
            ></div>
            <div
              class="p-content"
              v-if="
                $i18n.locale == 'en' &&
                post.translations &&
                post.translations.en &&
                post.translations.en.content
              "
              v-html="post.translations.en.content"
            ></div>
            <div class="pdf" v-if="post.document && post.document_action_type == 'download'">
              <div class="pdf-in">
                <div class="pdf-icon">
                  <a :href="host + post.document">
                    <img src="/img/pdf.png" alt="pdf">
                  </a>
                </div>
                <div class="pdf-title">
                  <p>title</p>
                  <p>
                    <a :href="host + post.document">Download Document</a>
                  </p>
                </div>
              </div>
            </div>
            <div class="viewer mt-4" v-if="post.document && post.document_action_type == 'show'">
              <object :data="host + post.document" width="100%" height="500px">
                <iframe :src="`https://docs.google.com/viewer?url=` + host + post.document + `&embedded=true`" width="100%" height="500px"></iframe>
              </object>
            </div>
            <div class="row mt-2">
              <div class="col-xl-8">
                <div class="copy">
                  <div class="copy-in">
                    <input type="text" :value="theurl" id="inputurl">
                    <button id="urlbtn">
                        <span class="tooltiptext" :data-title="$t('mycopyed')" id="copyurl">{{ $t('mycopy') }}</span>
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-xl-4">
                <div class="print">
                  <button onclick="print()">
                    <img src="/img/print.png" alt="print">
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <rightmenu></rightmenu>
        </div>
      </div>
    </div>
  </section>
  <myfooter></myfooter>
</template>

<script>
import axios from "axios";
import Banner from "../components/banner.vue";
import Myfooter from "../components/myfooter.vue";
import Myheader from "../components/myheader.vue";
import Mymenu from "../components/mymenu.vue";
import Rightmenu from "../components/rightmenu.vue";

export default {
  name: "page",
  components: { Myheader, Mymenu, Banner, Myfooter, Rightmenu },
  data() {
    return {
      id: this.$route.params.id,
      host: process.env.VUE_APP_API_HOST,
      url:
        process.env.VUE_APP_API_BASE_URL +
        `n/pages/` +
        this.$route.params.id +
        `/`,
      post: {},
      theurl: window.location
    };
  },
  methods: {
    getdata() {
      axios
        .get(this.url)
        .then((response) => {
          this.post = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getdata();
  },
  computed: {
    mytitle: function () {
      if (this.$i18n.locale == "uz") {
        if (this.post.translations) {
          if (this.post.translations.uz) {
            if (this.post.translations.uz.headline) {
              return this.post.translations.uz.headline;
            }
          }
        }
      }
      if (this.$i18n.locale == "oz") {
        if (this.post.translations) {
          if (this.post.translations.oz) {
            if (this.post.translations.oz.headline) {
              return this.post.translations.oz.headline;
            }
          }
        }
      }
      if (this.$i18n.locale == "ru") {
        if (this.post.translations) {
          if (this.post.translations.ru) {
            if (this.post.translations.ru.headline) {
              return this.post.translations.ru.headline;
            }
          }
        }
      }
      if (this.$i18n.locale == "en") {
        if (this.post.translations) {
          if (this.post.translations.en) {
            if (this.post.translations.en.headline) {
              return this.post.translations.en.headline;
            }
          }
        }
      }
    },
  },
};
</script>


<style scoped>
.p-title {
  margin-top: 30px;
  text-align: justify;
}
.p-title span {
  font-weight: 700;
  font-size: 24px;
}
.p-description {
  margin-top: 15px;
  text-align: justify;
}
.p-description span {
  font-weight: 600;
  font-size: 18px;
}
.p-content {
  margin-top: 15px;
  text-align: justify;
}
.p-content p {
  margin-top: 15px;
}
.p-content img {
  width: 100%;
  margin-bottom: 15px;
}
.p-content p img {
  width: 100%;
  margin-bottom: 15px;
}
.p-content video {
  width: 100%;
  margin-bottom: 15px;
}
.pdf {
  margin-top: 30px;
}
.pdf-in {
  display: flex;
}
.pdf-title {
  padding-left: 15px;
}
.pdf-title p {
  margin-bottom: 10px;
  font-weight: 700;
}
.pdf-title p a {
  font-weight: 600;
}
</style>
