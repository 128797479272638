export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !! En"])},
  "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span>Fund</span><br /><span>Energy Saving</span><br /><span>under the Ministry of Energy of the Republic of Uzbekistan</span>"])},
  "newstitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News"])},
  "newsall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All news"])},
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
  "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read more"])},
  "useful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Useful links"])},
  "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TENDERS AND COMPETITIONS"])},
  "ads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ads"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See them all"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "flogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Fund Energy Saving</p><p>under the Ministry of Energy of the Republic of Uzbekistan</p>"])},
  "flink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When using materials, a link to <a href='#'>www.iesf.uz</a> is required."])},
  "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All rights reserved"])},
  "ctrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention! If you find an error in the text, select it and press Ctrl + Enter to notify the administration"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helpline:"])},
  "myphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helpline:"])},
  "work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Working hours:"])},
  "lunch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunch:"])},
  "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reception days:"])},
  "call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call center:"])},
  "calltext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for electricity"])},
  "calltext2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for gas supply"])},
  "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["09.00 - 18.00"])},
  "mydays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monday - Friday: 9:00 - 18:00 </br> Lunch time: 13:00 - 14:00 </br> Saturday, Sunday: day off"])},
  "foto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photogallery"])},
  "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Videogallery"])},
  "leader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leader"])},
  "bio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bio"])},
  "function": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Function"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "fish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FIO"])},
  "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region"])},
  "district": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["District"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
  "aphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
  "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User file"])},
  "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit an application"])},
  "application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application for appeal"])},
  "app-submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send appeal"])},
  "app-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check status"])},
  "app-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appeal Code"])},
  "app-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
  "tender-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
  "tender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenders"])},
  "competition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contests"])},
  "mycopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy"])},
  "mycopyed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copied"])},
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connection"])},
  "app-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of application submission"])},
  "app-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application subject"])},
  "app-status-get": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application status"])},
  "app-get": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["your application has been accepted"])},
  "app-save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Number and Kod to know the status of the application"])},
  "app-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Go to) home page"])},
  "app_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number"])},
  "app_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod"])},
  "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
  "under_consideration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Under consideration"])},
  "app-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Considered, the answer was sent to"])},
  "app-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Considered, a reply letter has been sent to"])},
  "bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of the applicant bank (parent bank)"])},
  "clients_bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank customer name"])},
  "title_of_the_project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of the project"])},
  "tin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TIN of the bank"])},
  "goal_of_credit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purpose of the loan"])},
  "bank_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address of the bank"])},
  "project_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project amount"])},
  "requesting_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Requested amount"])},
  "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other information"])},
  "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The telephone number of the applicant bank"])},
  "certificate_of_bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brief information about the project"])},
  "energy_efficiency_doc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information on energy efficient equipment, installation and materials"])},
  "other_docs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
  "short_info_about_project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank certificate on approval of loan allocation"])},
  "response_via_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["response_via_mail"])},
  "response_via_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["response_via_email"])},
  "contact_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100047, Republic of Uzbekistan, Tashkent city, ave. Istikbol, 21"])},
  "email_bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank e-mail E-mail"])},
  "legal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal entity or SP"])},
  "legal_tin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal entity or SP TIN"])},
  "legal_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal entity or SP address"])},
  "subway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amir Temur Station"])},
  "cars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. 26, 102 (Landmark International Westminster University)"])},
  "captcha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the number above"])},
  "bayt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File size should not exceed 10MB"])}
}