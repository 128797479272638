<template>
  <myheader></myheader>
  <mymenu></mymenu>
  <banner></banner>
  <section class="page">
    <div class="container">
      <div class="row">
        <div class="col-xl-8 mb-3">
          <div class="ap-tab">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link active"
                  id="home-tab"
                  data-toggle="tab"
                  href="#home"
                  role="tab"
                  aria-controls="home"
                  aria-selected="true"
                  >{{ $t("app-submit") }}</a
                >
              </li>
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link"
                  id="profile-tab"
                  data-toggle="tab"
                  href="#profile"
                  role="tab"
                  aria-controls="profile"
                  aria-selected="false"
                  >{{ $t("app-status") }}</a
                >
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade show active"
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                <div class="app-form-one">
                  <form
                    method="post"
                    @submit.prevent="mysubmit()"
                    enctype="multipart/form-data"
                  >
                    <div class="form-group">
                      <label for="bank">{{ $t("bank") }}</label>
                      <vue-select
                        v-model="postdata.bank"
                        :options="banks"
                      ></vue-select>
                    </div>

                    <div class="form-group d-none">
                      <label for="clients_bank">{{ $t("clients_bank") }}</label>
                      <vue-select
                        v-model="postdata.clients_bank"
                        :options="banks"
                      ></vue-select>
                    </div>

                    <div class="form-group">
                      <label for="tin">{{ $t("tin") }}</label>
                      <input
                        v-model="postdata.tin"
                        type="number"
                        :class="[
                          postdata.v_tin
                            ? 'is-invalid form-control'
                            : 'form-control',
                        ]"
                        id="tin"
                      />
                    </div>

                    <div class="form-group">
                      <label for="bank_address">{{ $t("bank_address") }}</label>
                      <textarea
                        :class="[
                          postdata.v_bank_address
                            ? 'is-invalid form-control'
                            : 'form-control',
                        ]"
                        id="bank_address"
                        rows="2"
                        v-model="postdata.bank_address"
                      ></textarea>
                    </div>

                    <div class="form-group">
                      <label for="goal_of_credit">{{ $t("legal") }}</label>
                      <textarea
                        :class="[
                          postdata.v_goal_of_credit
                            ? 'is-invalid form-control'
                            : 'form-control',
                        ]"
                        id="goal_of_credit"
                        rows="1"
                        v-model="postdata.goal_of_credit"
                      ></textarea>
                    </div>

                    <div class="form-group">
                      <label for="goal_of_credit">{{ $t("legal_tin") }}</label>
                      <textarea
                        :class="[
                          postdata.v_goal_of_credit
                            ? 'is-invalid form-control'
                            : 'form-control',
                        ]"
                        id="goal_of_credit"
                        rows="1"
                        v-model="postdata.legal_tin"
                      ></textarea>
                    </div>

                    <div class="form-group">
                      <label for="goal_of_credit">{{
                        $t("legal_address")
                      }}</label>
                      <textarea
                        :class="[
                          postdata.v_goal_of_credit
                            ? 'is-invalid form-control'
                            : 'form-control',
                        ]"
                        id="goal_of_credit"
                        rows="1"
                        v-model="postdata.legal_address"
                      ></textarea>
                    </div>

                    <div class="form-group">
                      <label for="title_of_the_project">{{
                        $t("title_of_the_project")
                      }}</label>
                      <textarea
                        :class="[
                          postdata.v_title_of_the_project
                            ? 'is-invalid form-control'
                            : 'form-control',
                        ]"
                        id="title_of_the_project"
                        rows="2"
                        v-model="postdata.title_of_the_project"
                      ></textarea>
                    </div>

                    <div class="form-group">
                      <label for="project_amount">{{
                        $t("project_amount")
                      }}</label>
                      <input
                        v-model="postdata.project_amount"
                        type="number"
                        :class="[
                          postdata.v_project_amount
                            ? 'is-invalid form-control'
                            : 'form-control',
                        ]"
                        id="project_amount"
                      />
                    </div>

                    <div class="form-group d-none">
                      <label for="requesting_amount">{{
                        $t("requesting_amount")
                      }}</label>
                      <input
                        v-model="postdata.requesting_amount"
                        type="number"
                        :class="[
                          postdata.v_requesting_amount
                            ? 'is-invalid form-control'
                            : 'form-control',
                        ]"
                        id="requesting_amount"
                      />
                    </div>

                    <div class="form-group">
                      <label for="other">{{ $t("other") }}</label>
                      <textarea
                        :class="[
                          postdata.v_other
                            ? 'is-invalid form-control'
                            : 'form-control',
                        ]"
                        id="other"
                        rows="3"
                        v-model="postdata.other"
                      ></textarea>
                    </div>

                    <div class="form-group">
                      <label for="phone_number">{{ $t("phone_number") }}</label>
                      <input
                        v-model="postdata.phone_number"
                        type="text"
                        :class="[
                          postdata.v_phone_number
                            ? 'is-invalid form-control'
                            : 'form-control',
                        ]"
                        id="phone_number"
                        @input="acceptNumber()"
                        placeholder="(00) 000-00-00"
                      />
                    </div>

                    <div class="form-group">
                      <label for="email">{{ $t("email_bank") }}</label>
                      <input
                        v-model="postdata.email"
                        type="email"
                        :class="[
                          postdata.v_email
                            ? 'is-invalid form-control'
                            : 'form-control',
                        ]"
                        id="email"
                      />
                    </div>

                    <div class="custom-file mt-3" v-if="certificate_of_bank">
                      <label
                        :class="postdata.certificate_of_bank_class"
                        for="certificate_of_bank"
                        >{{ $t("certificate_of_bank") }}</label
                      >
                      <input
                        type="file"
                        class="custom-file-input"
                        id="certificate_of_bank"
                        @change="certificate_of_bank_event($event)"
                      />
                      <div class="text-muted mt-1">
                        <small :class="postdata.certificate_of_bank_small">{{
                          $t("bayt")
                        }}</small>
                      </div>
                    </div>

                    <div class="custom-file mt-3" v-if="energy_efficiency_doc">
                      <input
                        type="file"
                        class="custom-file-input"
                        id="energy_efficiency_doc"
                        @change="energy_efficiency_doc_event($event)"
                      />
                      <label
                        :class="postdata.energy_efficiency_doc_class"
                        for="energy_efficiency_doc"
                        >{{ $t("energy_efficiency_doc") }}</label
                      >
                      <div class="text-muted mt-1">
                        <small :class="postdata.energy_efficiency_doc_small">{{
                          $t("bayt")
                        }}</small>
                      </div>
                    </div>

                    <div class="custom-file mt-3" v-if="other_docs">
                      <input
                        type="file"
                        class="custom-file-input"
                        id="other_docs"
                        @change="other_docs_event($event)"
                      />
                      <label
                        :class="postdata.other_docs_class"
                        for="other_docs"
                        >{{ $t("other_docs") }}</label
                      >
                      <div class="text-muted mt-1">
                        <small :class="postdata.other_docs_small">{{
                          $t("bayt")
                        }}</small>
                      </div>
                    </div>

                    <div
                      class="custom-file mt-3"
                      v-if="short_info_about_project"
                    >
                      <input
                        type="file"
                        class="custom-file-input"
                        id="short_info_about_project"
                        @change="short_info_about_project_event($event)"
                      />
                      <label
                        :class="postdata.short_info_about_project_class"
                        for="short_info_about_project"
                        >{{ $t("short_info_about_project") }}</label
                      >
                      <div class="text-muted mt-1">
                        <small
                          :class="postdata.short_info_about_project_small"
                          >{{ $t("bayt") }}</small
                        >
                      </div>
                    </div>

                    <div class="form-group form-check mt-3">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="response_via_mail"
                        v-model="postdata.response_via_mail"
                      />
                      <label class="form-check-label" for="response_via_mail">{{
                        $t("response_via_mail")
                      }}</label>
                    </div>

                    <div class="form-group form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="response_via_email"
                        v-model="postdata.response_via_email"
                      />
                      <label
                        class="form-check-label"
                        for="response_via_email"
                        >{{ $t("response_via_email") }}</label
                      >
                    </div>

                    <div id="wrap" class="mt-2">
                      <div class="container">
                        <div class="row">
                          <template v-for="num in countNumber" :key="num.id">
                            <div class="num">
                              <div
                                v-for="n in numbers[num]"
                                class="cell"
                                :class="n ? 'captcha-blue' : ''"
                                :key="n.id"
                              ></div>
                            </div>
                          </template>
                        </div>
                      </div>
                      <div class="form-group">
                        <label for="captcha">{{ $t("captcha") }}</label>
                        <input
                          v-model.number="input"
                          type="number"
                          class="form-control"
                          id="captcha"
                        />
                      </div>
                      <div class="mt-2">
                        <div
                          class="alert"
                          v-if="input"
                          :class="
                            input == result ? 'alert-primary' : 'alert-danger'
                          "
                          role="alert"
                        >
                          {{ input }}
                        </div>
                        <!-- <div class="alert alert-warning" role="alert">
                          Result: {{ result }}
                        </div> -->
                      </div>
                    </div>

                    <div class="text-right mt-3">
                      <button type="submit" class="btn btn-success">
                        {{ $t("submit") }}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="profile"
                role="tabpanel"
                aria-labelledby="profile-tab"
              >
                <div class="app-form-two">
                  <form method="get" @submit.prevent="mystatus()">
                    <div class="form-group">
                      <label for="in10">{{ $t("app-number") }}</label>
                      <input
                        type="text"
                        v-model="poststatus.number"
                        class="form-control"
                        id="in10"
                      />
                    </div>
                    <div class="form-group">
                      <label for="in11">{{ $t("app-code") }}</label>
                      <input
                        type="text"
                        v-model="poststatus.code"
                        class="form-control"
                        id="in11"
                      />
                    </div>
                    <div class="text-right">
                      <button type="submit" class="btn btn-success">
                        {{ $t("submit") }}
                      </button>
                    </div>
                  </form>
                </div>
                <div class="card mt-3" v-if="getstatus.get">
                  <div class="card-header">
                    <h5 class="card-title mb-0">{{ getstatus.bank }}</h5>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-4 mt-2">
                        <span class="text-muted">{{ $t("app-date") }}:</span>
                      </div>
                      <div class="col-md-8 mt-2 span-right">
                        <span>{{ getstatus.created_at }}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4 mt-2">
                        <span class="text-muted">{{ $t("app-title") }}:</span>
                      </div>
                      <div class="col-md-8 mt-2 span-right">
                        <span>{{ getstatus.title_of_the_project }}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-4 mt-2">
                        <span class="text-muted"
                          >{{ $t("app-status-get") }}:</span
                        >
                      </div>
                      <div class="col-md-8 mt-2 span-right">
                        <span v-if="getstatus.status == 'received'">{{
                          $t("received")
                        }}</span>
                        <span
                          v-if="getstatus.status == 'under_consideration'"
                          >{{ $t("under_consideration") }}</span
                        >
                        <span
                          v-if="
                            getstatus.status == 'reviewed' &&
                            getstatus.response_via_email
                          "
                          >{{ $t("app-email") }}</span
                        >
                        <span
                          v-if="
                            getstatus.status == 'reviewed' &&
                            getstatus.response_via_mail
                          "
                          >{{ $t("app-mail") }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="alert alert-danger mt-3"
                  role="alert"
                  v-if="app_not"
                >
                  Мурожаат топилмади!
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4">
          <rightmenu></rightmenu>
        </div>
      </div>
    </div>
  </section>
  <myfooter></myfooter>
  <div class="my-success" v-show="mysuccess" v-if="successdata">
    <div id="card" class="animated fadeIn shadow">
      <div id="upper-side">
        <div class="success-icon text-center">
          <img src="/img/check.png" alt="check" />
        </div>
      </div>
      <div id="lower-side">
        <div class="card">
          <div class="card-header">
            <b>{{ successdata.email }}</b
            >&nbsp;
            <span>{{ $t("app-get") }}</span>
          </div>
          <div class="card-body">
            <p class="card-text">
              <b>{{ $t("app-save") }}</b>
            </p>
            <p id="message">
              <span>{{ $t("app_number") }}: &nbsp;</span>
              <b>{{ successdata.id }}</b>
            </p>
            <p id="message">
              <span>{{ $t("app_id") }}: &nbsp;</span>
              <b>{{ successdata.code }}</b>
            </p>
            <div class="d-flex justify-content-between">
              <div>
                <button class="btn btn-primary" @click="mysuccessclose()">
                  Ok
                </button>
              </div>
              <div>
                <router-link class="btn btn-primary" :to="{ name: 'home' }">{{
                  $t("app-home")
                }}</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Select2 from "vue3-select2-component";
import axios from "axios";
import Banner from "../components/banner.vue";
import Myfooter from "../components/myfooter.vue";
import myheader from "../components/myheader.vue";
import Mymenu from "../components/mymenu.vue";
import Rightmenu from "../components/rightmenu.vue";
export default {
  components: {
    myheader,
    Mymenu,
    Myfooter,
    Banner,
    Rightmenu,
    "vue-select": Select2,
  },
  name: "application",
  data() {
    return {
      url: "https://my-json-server.typicode.com/typicode/demo/posts",
      input: "",
      countMin: 4,
      countMax: 8,
      numbers: [
        // 0
        [1, 1, 1, 1, 0, 1, 1, 0, 1, 1, 0, 1, 1, 1, 1],
        // 1
        [0, 0, 1, 0, 1, 1, 1, 0, 1, 0, 0, 1, 0, 0, 1],
        // 2
        [1, 1, 1, 0, 0, 1, 0, 1, 1, 1, 0, 0, 1, 1, 1],
        // 3
        [1, 1, 1, 0, 0, 1, 0, 1, 0, 0, 0, 1, 1, 1, 1],
        // 4
        [1, 0, 1, 1, 0, 1, 1, 1, 1, 0, 0, 1, 0, 0, 1],
        // 5
        [1, 1, 1, 1, 0, 0, 1, 1, 1, 0, 0, 1, 1, 1, 1],
        // 6
        [1, 1, 1, 1, 0, 0, 1, 1, 1, 1, 0, 1, 1, 1, 1],
        // 7
        [1, 1, 1, 0, 0, 1, 0, 1, 0, 0, 1, 0, 0, 1, 0],
        // 8
        [1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 0, 1, 1, 1, 1],
        // 9
        [1, 1, 1, 1, 0, 1, 1, 1, 1, 0, 0, 1, 1, 1, 1],
      ],

      posturl: `https://admin.iesf.uz/api/a/application/`,
      geturl: `https://admin.iesf.uz/api/a/check_application_status/`,
      regionurl: `https://admin.iesf.uz/api/locations/cities/`,
      districturl: `https://admin.iesf.uz/api/locations/regions/?city_id=`,
      banksurl: `https://admin.iesf.uz/api/banks/banks/`,
      filesurl: `https://admin.iesf.uz/api/a/fields_status/`,
      region: null,
      district: null,
      mysuccess: false,
      default_district: false,
      load: false,
      app_not: false,
      banks: null,
      certificate_of_bank: false,
      energy_efficiency_doc: false,
      other_docs: false,
      short_info_about_project: false,
      getstatus: {
        created_at: null,
        bank: null,
        title_of_the_project: null,
        status: null,
        get: false,
        response_via_email: false,
        response_via_mail: false,
      },
      successdata: null,
      postdata: {
        bank: null,
        v_bank: false,
        clients_bank: 1,
        v_clients_bank: false,
        title_of_the_project: null,
        v_title_of_the_project: false,
        tin: null,
        v_tin: false,
        goal_of_credit: null,
        v_goal_of_credit: false,
        bank_address: null,
        v_bank_address: false,
        project_amount: null,
        v_project_amount: false,
        requesting_amount: 123,
        v_requesting_amount: false,
        other: null,
        v_other: false,
        phone_number: null,
        v_phone_number: false,
        email: null,
        v_email: false,
        legal_tin: null,
        legal_adress: null,

        certificate_of_bank: "",
        certificate_of_bank_class: "custom-file-label",
        certificate_of_bank_small: "",

        energy_efficiency_doc: "",
        energy_efficiency_doc_class: "custom-file-label",
        energy_efficiency_doc_small: "",

        other_docs: "",
        other_docs_class: "custom-file-label",
        other_docs_small: "",

        short_info_about_project: "",
        short_info_about_project_class: "custom-file-label",
        short_info_about_project_small: "",

        response_via_mail: false,
        response_via_email: false,
      },
      poststatus: {
        number: null,
        code: null,
      },
      files: null,
    };
  },
  methods: {
    cleardata() {
      this.postdata.bank = null;
      this.postdata.title_of_the_project = null;
      this.postdata.tin = null;
      this.postdata.goal_of_credit = null;
      this.postdata.bank_address = null;
      this.postdata.project_amount = null;
      this.postdata.requesting_amount = null;
      this.postdata.other = null;
      this.postdata.phone_number = null;
      this.postdata.email = null;
      this.postdata.response_via_mail = null;
      this.postdata.response_via_email = null;
    },
    async postsubmit(postdata) {
      let mypost = new FormData();
      for (let i in postdata) {
        mypost.append(i, postdata[i]);
      }
      await axios
        .post(this.posturl, mypost)
        .then((response) => {
          this.cleardata();
          this.mysuccess = true;
          this.successdata = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    valid_data() {
      const bank = !!this.postdata.bank;
      if (bank) {
        this.postdata.v_bank = false;
      } else {
        this.postdata.v_bank = true;
      }

      const title_of_the_project = !!this.postdata.title_of_the_project;
      if (title_of_the_project) {
        this.postdata.v_title_of_the_project = false;
      } else {
        this.postdata.v_title_of_the_project = true;
      }

      const tin = !!this.postdata.tin;
      if (tin) {
        this.postdata.v_tin = false;
      } else {
        this.postdata.v_tin = true;
      }

      const goal_of_credit = !!this.postdata.goal_of_credit;
      if (goal_of_credit) {
        this.postdata.v_goal_of_credit = false;
      } else {
        this.postdata.v_goal_of_credit = true;
      }

      const bank_address = !!this.postdata.bank_address;
      if (bank_address) {
        this.postdata.v_bank_address = false;
      } else {
        this.postdata.v_bank_address = true;
      }

      const project_amount = !!this.postdata.project_amount;
      if (project_amount) {
        this.postdata.v_project_amount = false;
      } else {
        this.postdata.v_project_amount = true;
      }

      const requesting_amount = !!this.postdata.requesting_amount;
      if (requesting_amount) {
        this.postdata.v_requesting_amount = false;
      } else {
        this.postdata.v_requesting_amount = true;
      }

      const other = !!this.postdata.other;
      if (other) {
        this.postdata.v_other = false;
      } else {
        this.postdata.v_other = true;
      }

      const phone_number = !!this.postdata.phone_number;
      if (phone_number) {
        this.postdata.v_phone_number = false;
      } else {
        this.postdata.v_phone_number = true;
      }

      const email = !!this.postdata.email;
      if (email) {
        this.postdata.v_email = false;
      } else {
        this.postdata.v_email = true;
      }

      const form_is_valid =
        bank &&
        title_of_the_project &&
        tin &&
        goal_of_credit &&
        bank_address &&
        project_amount &&
        requesting_amount &&
        other &&
        phone_number &&
        email;
      if (form_is_valid) {
        this.postsubmit(this.postdata);
      } else {
        console.log("not valid");
      }
    },
    mysubmit() {
      this.valid_data();
    },
    statussubmit(poststatus) {
      axios
        .get(this.geturl + poststatus.number + `/?code=` + poststatus.code)
        .then((response) => {
          this.getstatus.get = true;
          this.getstatus.bank = response.data.bank;
          this.getstatus.created_at = response.data.created_at;
          this.getstatus.title_of_the_project =
            response.data.title_of_the_project;
          this.getstatus.status = response.data.status;
          this.getstatus.response_via_email = response.data.response_via_email;
          this.getstatus.response_via_mail = response.data.response_via_mail;
          this.app_not = false;
        })
        .catch((error) => {
          this.app_not = true;
          this.getstatus.get = false;
          console.log(error);
        });
    },
    mystatus() {
      this.statussubmit(this.poststatus);
    },
    getdata() {
      axios
        .get(this.regionurl)
        .then((response) => {
          this.region = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      axios
        .get(this.banksurl)
        .then((response) => {
          this.banks = response.data;
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
      axios
        .get(this.filesurl)
        .then((response) => {
          this.files = response.data;
          this.certificate_of_bank = response.data[0].certificate_of_bank;
          this.energy_efficiency_doc = response.data[0].energy_efficiency_doc;
          this.other_docs = response.data[0].other_docs;
          this.short_info_about_project =
            response.data[0].short_info_about_project;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    certificate_of_bank_event(event) {
      let file = event.target.files[0];
      if (file.size < 1024 * 1024 * 10) {
        this.postdata.certificate_of_bank = event.target.files[0];
        this.postdata.certificate_of_bank_class =
          "custom-file-label border-success";
        console.log(1, this.postdata.certificate_of_bank);
        this.postdata.certificate_of_bank_small = "d-none";
      } else {
        this.postdata.certificate_of_bank = null;
        this.postdata.certificate_of_bank_class =
          "custom-file-label border-danger";
        console.log(2, this.postdata.certificate_of_bank);
        this.postdata.certificate_of_bank_small = "d-block text-danger";
      }
    },
    energy_efficiency_doc_event(event) {
      let file = event.target.files[0];
      if (file.size < 1024 * 1024 * 10) {
        this.postdata.energy_efficiency_doc = event.target.files[0];
        this.postdata.energy_efficiency_doc_class =
          "custom-file-label border-success";
        console.log(1, this.postdata.energy_efficiency_doc);
        this.postdata.energy_efficiency_doc_small = "d-none";
      } else {
        this.postdata.energy_efficiency_doc = null;
        this.postdata.energy_efficiency_doc_class =
          "custom-file-label border-danger";
        console.log(2, this.postdata.energy_efficiency_doc);
        this.postdata.energy_efficiency_doc_small = "d-block text-danger";
      }
    },
    other_docs_event(event) {
      let file = event.target.files[0];
      if (file.size < 1024 * 1024 * 10) {
        this.postdata.other_docs = event.target.files[0];
        this.postdata.other_docs_class = "custom-file-label border-success";
        console.log(1, this.postdata.other_docs);
        this.postdata.other_docs_small = "d-none";
      } else {
        this.postdata.other_docs = null;
        this.postdata.other_docs_class = "custom-file-label border-danger";
        console.log(2, this.postdata.other_docs);
        this.postdata.other_docs_small = "d-block text-danger";
      }
    },
    short_info_about_project_event(event) {
      let file = event.target.files[0];
      if (file.size < 1024 * 1024 * 10) {
        this.postdata.short_info_about_project = event.target.files[0];
        this.postdata.short_info_about_project_class =
          "custom-file-label border-success";
        console.log(1, this.postdata.short_info_about_project);
        this.postdata.short_info_about_project_small = "d-none";
      } else {
        this.postdata.short_info_about_project = null;
        this.postdata.short_info_about_project_class =
          "custom-file-label border-danger";
        console.log(2, this.postdata.short_info_about_project);
        this.postdata.short_info_about_project_small = "d-block text-danger";
      }
    },
    mysuccessclose() {
      this.mysuccess = false;
    },
    acceptNumber() {
      var x = this.postdata.phone_number
        .replace(/\D/g, "")
        .match(/(\d{0,2})(\d{0,3})(\d{0,2})(\d{0,2})/);
      this.postdata.phone_number = !x[2]
        ? x[1]
        : "(" +
          x[1] +
          ") " +
          x[2] +
          (x[3] ? "-" + x[3] : "") +
          (x[4] ? "-" + x[4] : "");
    },
    get_banks(event) {
      console.log(event.target.value);
    },
    valid() {
      return this.input == this.result;
    },
  },
  mounted() {
    this.getdata();
  },
  computed: {
    result() {
      return this.countNumber.join("");
    },
    countNumber() {
      let num = [];
      for (var i = 0; i < this.randCount; i++) {
        num.push(Math.floor(Math.random() * 10));
      }
      return num;
    },
    randCount() {
      return Math.floor(
        this.countMin + Math.random() * (this.countMax + 1 - this.countMin)
      );
      // return randomMinMax(4, 10);
    },
  },
  created() {},
};
</script>

<style scoped>
.captcha-blue {
  background-color: rgba(0, 120, 212, 0.92);
}
.cell {
  height: 6px;
  width: 8px;
}
.num {
  margin-bottom: 10px;
  max-width: 30px;
  display: flex;
  flex-wrap: wrap;
}
.ap-tab ul {
  justify-content: space-around;
  margin-bottom: 20px;
}
.ap-tab ul li a {
  font-weight: 600;
  font-size: 18px;
}
.my-success {
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  z-index: 20;
  background-color: rgb(0 123 255 / 50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
#card {
  width: 450px;
  display: block;
  margin: auto;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  overflow: hidden;
}

#upper-side {
  padding: 2em;
  background-color: rgba(0, 120, 212, 1);
  display: block;
  color: #fff;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

#lower-side {
  padding: 15px;
  background: #fff;
  display: block;
}

#message {
  color: #757575;
  text-align: left;
}

.span-right span {
  font-weight: 600;
}
</style>