export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !! Oz"])},
  "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span>O‘zbekiston Respublikasi energetika vazirligi huzuridagi</span><br /><span>BUDJETDAN TASHQARI TARMOQLARARO ENERGIYANI TEJASH JAMG‘ARMASI</span>"])},
  "newstitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yangiliklar"])},
  "newsall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barcha yangiliklar"])},
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biz haqimizda"])},
  "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batafsil"])},
  "useful": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foydali havolalar"])},
  "choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenderlar va tanlovlar"])},
  "ads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E'LONLAR"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barchasini ko'rish"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sayt bo'ylab qidirish"])},
  "flogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>O‘zbekiston Respublikasi energetika vazirligi huzuridagi</p><p>BUDJETDAN TASHQARI TARMOQLARARO ENERGIYANI TEJASH JAMG‘ARMASI</p>"])},
  "flink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Materiallardan foydalanilganda, <a href='#'>www.iesf.uz</a> saytiga havola qilish majburiydir"])},
  "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barcha huquqlar himoyalangan"])},
  "ctrl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diqqat! Agar siz matnda xato topsangiz, uni tanlang va ma'muriyatni xabardor qilish uchun Ctrl + Enter ni bosing"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ishonch telefoni:"])},
  "myphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon:"])},
  "work": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ish tartibi:"])},
  "lunch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tushlik:"])},
  "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qabul kunlari:"])},
  "call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call-markaz:"])},
  "calltext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["elektroenergiya qismi bo'yicha"])},
  "calltext2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gaz ta'minoti bo'yicha"])},
  "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["09.00 dan 18.00 gacha"])},
  "mydays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dushanba - juma: 9:00 - 18:00</br> tushlik vaqti: 13:00 - 14:00</br> shanba, yakshanba: dam olish kuni"])},
  "foto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fotogalereya"])},
  "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Videogalereya"])},
  "leader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rahbariyat"])},
  "bio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biografiya"])},
  "function": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funksiyalari"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "fish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FISh"])},
  "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viloyat"])},
  "district": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuman"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manzil"])},
  "aphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta'rif"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izoh"])},
  "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foydalanuvchi fayli"])},
  "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yuborish"])},
  "application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Murojaat formasi"])},
  "app-submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Murojaat yuborish"])},
  "app-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Murojaat holatini tekshirish"])},
  "app-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Murojaat raqami"])},
  "app-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod"])},
  "tender-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barchasi"])},
  "tender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tenderlar"])},
  "competition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tanlovlar"])},
  "mycopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nusxa olish"])},
  "mycopyed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nusxa olindi"])},
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bogʼlanish"])},
  "app-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ariza yuborilgan sana"])},
  "app-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ariza mavzusi"])},
  "app-status-get": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ariza statusi"])},
  "app-get": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["murojaatingiz qabul qilindi "])},
  "app-save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Murojaat statusini bilish uchun Raqam va Kod ni saqlab qoling"])},
  "app-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bosh sahifaga o'tish"])},
  "app_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raqam"])},
  "app_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kod"])},
  "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yangi"])},
  "under_consideration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ko'rib chiqilmoqda"])},
  "app-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ko'rib chiqildi, javob ko'rsatilgan elektron manzilga yuborildi"])},
  "app-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ko‘rib chiqildi, javob xati ko'rsatilgan manzilga yuborildi"])},
  "bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Murojaat qiluvchi Bank nomi (Bosh bank)"])},
  "clients_bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mijoz banki nomi"])},
  "title_of_the_project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loyiha nomi"])},
  "tin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank STIRi"])},
  "goal_of_credit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kredit maqsadi"])},
  "bank_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank manzili"])},
  "project_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loyiha summasi"])},
  "requesting_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["So‘ralayotgan summa"])},
  "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boshqa ma’lumotlar"])},
  "phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Murojaat qiluvchi bank telefon raqami"])},
  "certificate_of_bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loyiha haqida qisqa ma’lumot"])},
  "energy_efficiency_doc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energiyani tejovchi qurilma, moslama va materiallar bo‘yicha ma’lumot"])},
  "other_docs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boshqalar"])},
  "short_info_about_project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Bankning kredit ajratilishini tasdiqlovchi ma’lumotnomasi"])},
  "response_via_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Natijani manzilli pochta orqali olish"])},
  "response_via_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Natijani elektron pochta orqali olish"])},
  "contact_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100047, O‘zbekiston Respublikasi, Tоshkent sh, Istikbol ko‘chasi, 21"])},
  "email_bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank elektron pochtasi E-mail"])},
  "legal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yuridik shaxs yoki YaTT"])},
  "legal_tin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yuridik shaxs yoki YaTT STIRi"])},
  "legal_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yuridik shaxs yoki YaTT manzili"])},
  "subway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аmir Temur bekati"])},
  "cars": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["№ 26, 102 (Moʼljal Toshkent Xalqaro Vestminster universiteti)"])},
  "captcha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yuqoridagi raqamni kiriting"])},
  "bayt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fayl hajmi 10 mb dan oshmasligi kerak"])}
}