<template>
  <ul class="full-ul">
    <li v-for="item in datatree" :key="item.id">
      <router-link
        class="full-link"
        v-if="item.children.length === 0 && !item.news_id"
        :to="{ name: item.name }"
        >
        <span v-if="$i18n.locale == 'uz' && item.translations && item.translations.uz && item.translations.uz.title">{{ item.translations.uz.title }}</span>
        <span v-if="$i18n.locale == 'ru' && item.translations && item.translations.ru && item.translations.ru.title">{{ item.translations.ru.title }}</span>
        <span v-if="$i18n.locale == 'oz' && item.translations && item.translations.oz && item.translations.oz.title">{{ item.translations.oz.title }}</span>
        <span v-if="$i18n.locale == 'en' && item.translations && item.translations.en && item.translations.en.title">{{ item.translations.en.title }}</span>
        </router-link
      >
      
      <router-link
        class="full-link"
        v-if="item.children.length === 0 && item.news_id"
        :to="{ name: 'page', params: { id: item.news_id } }"
        >
        <span v-if="$i18n.locale == 'uz' && item.translations && item.translations.uz && item.translations.uz.title">{{ item.translations.uz.title }}</span>
        <span v-if="$i18n.locale == 'ru' && item.translations && item.translations.ru && item.translations.ru.title">{{ item.translations.ru.title }}</span>
        <span v-if="$i18n.locale == 'oz' && item.translations && item.translations.oz && item.translations.oz.title">{{ item.translations.oz.title }}</span>
        <span v-if="$i18n.locale == 'en' && item.translations && item.translations.en && item.translations.en.title">{{ item.translations.en.title }}</span>
        </router-link
      >
      
      <span class="full-link" v-if="item.children.length > 0">
        <span v-if="$i18n.locale == 'uz' && item.translations && item.translations.uz && item.translations.uz.title">{{ item.translations.uz.title }}</span>
        <span v-if="$i18n.locale == 'ru' && item.translations && item.translations.ru && item.translations.ru.title">{{ item.translations.ru.title }}</span>
        <span v-if="$i18n.locale == 'oz' && item.translations && item.translations.oz && item.translations.oz.title">{{ item.translations.oz.title }}</span>
        <span v-if="$i18n.locale == 'en' && item.translations && item.translations.en && item.translations.en.title">{{ item.translations.en.title }}</span>
      </span>
      <treefull
        v-if="item.children.length > 0"
        :datatree="item.children"
      ></treefull>
    </li>
  </ul>
</template>

<script>
export default {
  name: "treefull",
  props: ["datatree"],
};
</script>