
<template>
  <myheader></myheader>
  <mymenu></mymenu>
  <banner :title="$t('newstitle')"></banner>
  <section class="page">
    <div class="container">
      <div class="row">
        <div class="col-md-8">
          <div class="left">
            <div class="p-title">
              <span
                v-if="
                  $i18n.locale == 'uz' &&
                  post.translations &&
                  post.translations.uz &&
                  post.translations.uz.headline
                "
                >{{ post.translations.uz.headline }}</span
              >
              <span
                v-if="
                  $i18n.locale == 'ru' &&
                  post.translations &&
                  post.translations.ru &&
                  post.translations.ru.headline
                "
                >{{ post.translations.ru.headline }}</span
              >
              <span
                v-if="
                  $i18n.locale == 'oz' &&
                  post.translations &&
                  post.translations.oz &&
                  post.translations.oz.headline
                "
                >{{ post.translations.oz.headline }}</span
              >
              <span
                v-if="
                  $i18n.locale == 'en' &&
                  post.translations &&
                  post.translations.en &&
                  post.translations.en.headline
                "
                >{{ post.translations.en.headline }}</span
              >
            </div>
            <div class="p-image mt-3" v-if="post.image">
              <img :src="host + post.image" alt="image">
            </div>
            <div class="p-description">
              <span
                v-if="
                  $i18n.locale == 'uz' &&
                  post.translations &&
                  post.translations.uz &&
                  post.translations.uz.description
                "
                >{{ post.translations.uz.description }}</span
              >
              <span
                v-if="
                  $i18n.locale == 'ru' &&
                  post.translations &&
                  post.translations.ru &&
                  post.translations.ru.description
                "
                >{{ post.translations.ru.description }}</span
              >
              <span
                v-if="
                  $i18n.locale == 'oz' &&
                  post.translations &&
                  post.translations.oz &&
                  post.translations.oz.description
                "
                >{{ post.translations.oz.description }}</span
              >
              <span
                v-if="
                  $i18n.locale == 'en' &&
                  post.translations &&
                  post.translations.en &&
                  post.translations.en.description
                "
                >{{ post.translations.en.description }}</span
              >
            </div>
            <div
              class="p-content"
              v-if="
                $i18n.locale == 'uz' &&
                post.translations &&
                post.translations.uz &&
                post.translations.uz.content
              "
              v-html="post.translations.uz.content"
            ></div>
            <div
              class="p-content"
              v-if="
                $i18n.locale == 'ru' &&
                post.translations &&
                post.translations.ru &&
                post.translations.ru.content
              "
              v-html="post.translations.ru.content"
            ></div>
            <div
              class="p-content"
              v-if="
                $i18n.locale == 'oz' &&
                post.translations &&
                post.translations.oz &&
                post.translations.uz.content
              "
              v-html="post.translations.oz.content"
            ></div>
            <div
              class="p-content"
              v-if="
                $i18n.locale == 'en' &&
                post.translations &&
                post.translations.en &&
                post.translations.en.content
              "
              v-html="post.translations.en.content"
            ></div>
            <div class="pdf" v-if="post.document && post.document_action_type == 'download'">
              <div class="pdf-in">
                <div class="pdf-icon">
                  <a :href="host + post.document" download="true">
                    <img src="/img/pdf.png" alt="pdf">
                  </a>
                </div>
                <div class="pdf-title">
                  <p>title</p>
                  <p>
                    <a :href="host + post.document" download="true">Download Document</a>
                  </p>
                </div>
              </div>
            </div>
            <div class="viewer mt-4" v-if="post.document && post.document_action_type == 'show'">
              <object :data="host + post.document" width="100%" height="500px">
                <iframe :src="`https://docs.google.com/viewer?url=` + host + post.document + `&embedded=true`" width="100%" height="500px"></iframe>
              </object>
            </div>
            <div class="row mt-2">
              <div class="col-xl-8">
                <div class="copy">
                  <div class="copy-in">
                    <input type="text" :value="theurl" id="inputurl" />
                    <button id="urlbtn">
                      <span
                        class="tooltiptext"
                        :data-title="$t('mycopyed')"
                        id="copyurl"
                        >{{ $t("mycopy") }}</span
                      >
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-xl-4">
                <div class="detail-eye">
                  
                </div>
                <div class="print">
                  
                  <button onclick="print()">
                    <img src="/img/print.png" alt="print" />
                  </button>
                  <span>&nbsp;&nbsp;&nbsp;</span>
                  <img src="/img/eyen.png" alt="image">
                  <span>&nbsp;&nbsp;</span>
                  <span>{{ post.views_count }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <rightmenu></rightmenu>
        </div>
      </div>
    </div>
  </section>
  <myfooter></myfooter>
</template>

<script>
import axios from "axios";
import myheader from "../components/myheader.vue";
import mymenu from "../components/mymenu.vue";
import banner from "../components/banner.vue";
import myfooter from "../components/myfooter.vue";
import rightmenu from "../components/rightmenu.vue";
export default {
  components: { myheader, mymenu, banner, myfooter, rightmenu },
  name: "newsdetail",
  data() {
    return {
      id: this.$route.params.id,
      host: process.env.VUE_APP_API_HOST,
      url:
        process.env.VUE_APP_API_BASE_URL +
        `n/news/` +
        this.$route.params.id +
        `/`,
      post: {},
      theurl: window.location,
      title: 'News title',
    };
  },
  head: {
    title () {
      return {
        inner: this.title
      }
    },
    meta: [
      { name: 'description', content: 'My description' }
    ]
  },
  methods: {
    getdata() {
      axios
        .get(this.url)
        .then((response) => {
          this.post = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getdata();
  },
  created() {
    
  },
};
</script>

<style scoped>
.p-title {
  margin-top: 30px;
  text-align: justify;
}
.p-title span {
  font-weight: 700;
  font-size: 24px;
}
.p-description {
  margin-top: 15px;
  text-align: justify;
}
.p-description span {
  font-weight: 600;
  font-size: 18px;
}
.p-content {
  margin-top: 15px;
  text-align: justify;
}
.p-content p {
  margin-top: 15px;
}
.p-content img {
  width: 100%;
  margin-bottom: 15px;
}
.p-content p img {
  width: 100%;
  margin-bottom: 15px;
}
.p-content video {
  width: 100%;
  margin-bottom: 15px;
}
.pdf {
  margin-top: 30px;
}
.pdf-in {
  display: flex;
}
.pdf-title {
  padding-left: 15px;
}
.pdf-title p {
  margin-bottom: 10px;
  font-weight: 700;
}
.pdf-title p a {
  font-weight: 600;
}
.p-image img {
  width: 100%;
}
</style>
