<template>
  <div class="right">
    <div class="right-news" v-for="item in mynews" :key="item.id">
      <div class="news-right">
        <div class="news-right-img">
          <router-link :to="{ name: 'newsdetail', params: { id: item.id } }">
            <img :src="item.image" />
          </router-link>
        </div>
        <div class="news-right-title">
          <router-link :to="{ name: 'newsdetail', params: { id: item.id } }">           
            <span v-if="$i18n.locale == 'uz' && item.translations && item.translations.uz && item.translations.uz.headline">{{ item.translations.uz.headline }}</span>
            <span v-if="$i18n.locale == 'ru' && item.translations && item.translations.ru && item.translations.ru.headline">{{ item.translations.ru.headline }}</span>
            <span v-if="$i18n.locale == 'oz' && item.translations && item.translations.oz && item.translations.oz.headline">{{ item.translations.oz.headline }}</span>
            <span v-if="$i18n.locale == 'en' && item.translations && item.translations.en && item.translations.en.headline">{{ item.translations.en.headline }}</span>
          </router-link>
        </div>
        <div class="news-right-date">
          <div class="news-right-time">
            <img src="/img/date.png" />
            <span>{{ item.created_at }}</span>
            <span> | </span>
            <span>{{ item.time }}</span>
          </div>
          <div class="news-right-eye">
            <img src="/img/eyen.png" />
            <span>{{ item.views_count }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "rightmenu",
  components: {},
  data() {
    return {
      url: process.env.VUE_APP_API_BASE_URL + `n/news/`,
      mynews: [],
    };
  },
  mounted() {
    this.getdata();
  },
  methods: {
    getdata() {
      axios
        .get(this.url)
        .then((response) => {
          this.mynews = response.data.slice(0, 3);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {},
};
</script>

<style scoped>
.right-news {
  margin-top: 30px;
}
</style>