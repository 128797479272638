<template>
  <myheader></myheader>
  <mymenu></mymenu>
  <banner :title="$t('choose')"></banner>
  <section class="page">
    <div class="container">
      <div class="row">
        <div class="col-lg-6" v-for="item in tender" :key="item.id">
          <div class="choice">
            <router-link :to="{ name: 'newsdetail', params: { id: item.id } }">
              <div class="choice-in">
                <div class="choice-title">
                  <span
                    v-if="
                      $i18n.locale == 'uz' &&
                      item.translations &&
                      item.translations.uz &&
                      item.translations.uz.headline
                    "
                    >{{ item.translations.uz.headline }}</span
                  >
                  <span
                    v-if="
                      $i18n.locale == 'ru' &&
                      item.translations &&
                      item.translations.ru &&
                      item.translations.ru.headline
                    "
                    >{{ item.translations.ru.headline }}</span
                  >
                  <span
                    v-if="
                      $i18n.locale == 'oz' &&
                      item.translations &&
                      item.translations.oz &&
                      item.translations.oz.headline
                    "
                    >{{ item.translations.oz.headline }}</span
                  >
                  <span
                    v-if="
                      $i18n.locale == 'en' &&
                      item.translations &&
                      item.translations.en &&
                      item.translations.en.headline
                    "
                    >{{ item.translations.en.headline }}</span
                  >
                </div>
                <div class="news-right-date">
                  <div class="news-right-time">
                    <img src="/img/date.png" />
                    <span>{{ item.created_at }}</span>
                    <span> | </span>
                    <span>{{ item.time }}</span>
                  </div>
                  <div class="news-right-eye">
                    <img src="/img/eyen.png" />
                    <span>{{ item.views_count }}</span>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
  <myfooter></myfooter>
</template>

<script>
import axios from "axios";
import Banner from "../components/banner.vue";
import Myfooter from "../components/myfooter.vue";
import myheader from "../components/myheader.vue";
import Mymenu from "../components/mymenu.vue";
export default {
  components: { myheader, Mymenu, Banner, Myfooter },
  name: "tenderlist",
  data() {
    return {
      url: process.env.VUE_APP_API_BASE_URL + `n/tenders/`,
      tender: null,
    };
  },
  methods: {
    getdata() {
      axios
        .get(this.url)
        .then((response) => {
          console.log(response);
          this.tender = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getdata();
  },
  computed: {},
};
</script>